import { Container, Text, useMantineTheme } from "@mantine/core";
import HelloMyNameIsSticker from "../components/Widgets/HelloMyNameIsSticker";

const AboutPage = () => {
  const theme = useMantineTheme();

  const gradient =
    theme.colorScheme === "dark"
      ? { from: "yellow", to: "grape", deg: 120 }
      : { from: "indigo", to: "green", deg: 120 };

  return (
    <Container sx={{ paddingTop: "1em" }}>
      <HelloMyNameIsSticker text="Tom" />

      <Text
        component="p"
        variant="gradient"
        gradient={gradient}
        size="xl"
        weight={700}
        sx={{ textAlign: "center" }}
      >
        I'm a full stack developer based in Australia.
      </Text>

      <p>
        This is one of my personal projects that I use to test out different
        technologies. I've worked with several technology stacks and I'm always
        open to trying out new things.
      </p>
      <p>I'm hoping to add more to this site in the near future.</p>
    </Container>
  );
};

export default AboutPage;
