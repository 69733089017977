import { Client } from "../../data/StaticPortfolioTypes";
import { useMemo, useState } from "react";
import { PortfolioNodeType } from "../../utils/portfolioUtils";
import {
  getNodeOptionsFromClientByType,
  PortfolioNodeOption,
} from "../../utils/portfolioTreeUtils";
import {
  Avatar,
  Grid,
  Group,
  List,
  Select,
  Stack,
  Text,
  Timeline,
} from "@mantine/core";

const distantFuture = new Date(8640000000000000);

enum SortByOptions {
  date = "Date",
  time = "Time",
}
enum SortDirection {
  asc = "Ascending",
  desc = "Descending",
}

interface PortfolioNodeOptionWithYears extends PortfolioNodeOption {
  years: number;
}

interface Props {
  clients: Client[];
  searchText: string;
}

const yearsAgo = (
  start: Date | undefined,
  end: Date | undefined,
  sortBy: SortByOptions
): number | undefined => {
  const currentYear = new Date().getFullYear();
  const useEndDate = sortBy === SortByOptions.time;
  if (useEndDate) {
    return (
      (end?.getFullYear() ?? currentYear) -
      (start?.getFullYear() ?? currentYear)
    );
  }
  return start ? currentYear - (end?.getFullYear() ?? currentYear) : undefined;
};

const PortfolioTimeline = ({ clients, searchText }: Props) => {
  const [sortBy, setSortBy] = useState(SortByOptions.date);
  const [sortDir, setSortDir] = useState(SortDirection.desc);
  const [view, setView] = useState(PortfolioNodeType.clients);

  const nodes: PortfolioNodeOptionWithYears[] = useMemo(() => {
    const results = getNodeOptionsFromClientByType(
      clients,
      searchText,
      view
    ).map((n) => {
      const years = yearsAgo(n.startDate, n.endDate, sortBy) ?? 0;
      return {
        ...n,
        years,
      };
    });

    const sortDesc = sortDir === SortDirection.desc;
    if (sortBy === SortByOptions.time) {
      return results.sort((a, b) => {
        return sortDesc ? b.years - a.years : a.years - b.years;
      });
    }
    return results.sort((a, b) => {
      const dateA = a.endDate ?? a.startDate ?? distantFuture;
      const dateB = b.endDate ?? b.startDate ?? distantFuture;
      return sortDesc
        ? dateB.getTime() - dateA.getTime()
        : dateA.getTime() - dateB.getTime();
    });
  }, [clients, searchText, sortBy, sortDir, view]);

  const showTimeAgo = sortBy === SortByOptions.date;

  return (
    <Grid ml={1} mt={1}>
      <Stack pr="lg">
        <Select
          data={Object.values(PortfolioNodeType)}
          onChange={(v) => (v ? setView(v as PortfolioNodeType) : undefined)}
          value={view}
        />
        <Select
          data={Object.values(SortByOptions)}
          onChange={(v) => (v ? setSortBy(v as SortByOptions) : undefined)}
          value={sortBy}
        />
        <Select
          data={Object.values(SortDirection)}
          onChange={(v) => (v ? setSortDir(v as SortDirection) : undefined)}
          value={sortDir}
        />
      </Stack>
      <Timeline sx={{ width: 500 }}>
        {nodes.map((n, i) => {
          return (
            <Timeline.Item
              bullet={n.image ? <Avatar size={24} src={n.image} /> : undefined}
              bulletSize={32}
              key={i}
              title={n.name}
            >
              {n.startDate && (
                <Group>
                  <Text size="xs">
                    {!n.endDate?.getFullYear()
                      ? `${n.startDate.getFullYear()} to current`
                      : n.startDate.getFullYear() === n.endDate.getFullYear()
                      ? n.startDate.getFullYear()
                      : `${n.startDate.getFullYear()} to ${n.endDate.getFullYear()}`}
                  </Text>
                  {n.years !== undefined && (
                    <Text italic size="xs">
                      {n.years < 1
                        ? "Less than a year"
                        : `${n.years} year${n.years > 1 ? "s" : ""}`}
                      {showTimeAgo ? " ago" : ""}
                    </Text>
                  )}
                </Group>
              )}
              {n.personalDescription && (
                <Text color="dimmed" size="sm">
                  {n.personalDescription}
                </Text>
              )}
              {n.children?.length && (
                <List>
                  {n.children.map((c, i) => (
                    <List.Item key={i}>
                      <Text size="sm">{c.name}</Text>
                    </List.Item>
                  ))}
                </List>
              )}
            </Timeline.Item>
          );
        })}
      </Timeline>
    </Grid>
  );
};

export default PortfolioTimeline;
