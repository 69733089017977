export enum Industries {
  Consulting = "Consulting",
  Education = "Education",
  Insurance = "Insurance",
  Government = "Government",
  None = "None",
  OnlineMarketplace = "Online Marketplace",
  SupportService = "Support Service",
}

export enum TechnologyType {
  Backend = "Backend",
  Database = "Database",
  Design = "Design",
  DevOps = "DevOps",
  Frontend = "Frontend",
  KnowledgeSharing = "Knowledge Sharing",
  Language = "Language",
  Logging = "Logging",
  Service = "Service",
  SourceControl = "Source Control",
  Tests = "Tests",
}

export interface PortfolioNode {
  description?: string;
  id: string;
  image: string;
  imageFull?: string;
  keywords?: string[];
  name: string;
  personalDescription?: string;
  shortName?: string;
  url?: string;
}

export interface Client extends PortfolioNode {
  endDate?: Date;
  industry: string;
  projects: Project[];
  startDate?: Date;
}

export interface Language extends PortfolioNode {
  type: TechnologyType;
}

export interface Project extends PortfolioNode {
  technologies: Technology[];
}

export interface Technology extends PortfolioNode {
  languages?: Language[];
  platform?: PortfolioNode;
  types: TechnologyType[];
}
