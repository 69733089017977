import AppLogo from "./AppLogo";
import { createStyles, Group } from "@mantine/core";

const useStyles = createStyles(() => ({
  heading: {
    fontSize: 23,
  },
  logo: {
    width: 30,
  },
}));

const AppLogoWithText = () => {
  const { classes } = useStyles();
  return (
    <Group position="center" spacing={8}>
      <AppLogo className={classes.logo} />
      <h1 className={classes.heading}>Tsco Dev</h1>
    </Group>
  );
};

export default AppLogoWithText;
