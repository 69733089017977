import { Button, Card, createStyles, Grid, Stack, Text } from "@mantine/core";
import { PortfolioNode } from "../../data/StaticPortfolioTypes";
import { isClientNode } from "../../utils/portfolioUtils";

const useStyles = createStyles(() => ({
  card: {
    minWidth: 500,
  },
}));

interface Props {
  clearSelection: () => void;
  node: PortfolioNode;
  showAllNodes: () => void;
}

const DateDetails = ({ node }: { node: PortfolioNode }) => {
  if (isClientNode(node) && node.startDate) {
    const startYear = node.startDate.getFullYear();
    const endYear = node.endDate?.getFullYear();
    return (
      <Text size="xs">
        {!endYear
          ? `${startYear} to current`
          : startYear === endYear
          ? startYear
          : `${startYear} to ${endYear}`}
      </Text>
    );
  }
  return null;
};

const PortfolioNodeDetails = ({
  clearSelection,
  node,
  showAllNodes,
}: Props) => {
  const { classes } = useStyles();
  return (
    <Card className={classes.card} radius="md" p="lg" shadow="sm" withBorder>
      <Stack>
        <Grid grow>
          <Grid.Col sx={{ flex: 0 }}>
            <img
              alt={`Logo for ${node.name}`}
              height={120}
              src={node.image}
              width={120}
            />
          </Grid.Col>
          <Grid.Col sx={{ flex: 1 }}>
            <Stack spacing={0}>
              <Text size="xl">{node.shortName ?? node.name}</Text>
              {node.shortName && (
                <Text size="sm" sx={{ mb: 1 }}>
                  {node.name}
                </Text>
              )}
              <DateDetails node={node} />
              {node.url && <a href={node.url}>{node.url}</a>}
              {node.description && (
                <Text mt={8} size="sm">
                  {node.description}
                </Text>
              )}
            </Stack>
          </Grid.Col>
        </Grid>
        <div>
          <Button onClick={clearSelection} mr={20} variant="outline">
            Unselect
          </Button>
          <Button onClick={showAllNodes}>Show all relationships</Button>
        </div>
        {node.personalDescription && (
          <Text sx={{ mt: 2 }}>{node.personalDescription}</Text>
        )}
      </Stack>
    </Card>
  );
};

export default PortfolioNodeDetails;
