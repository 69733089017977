import { PortfolioNodeObject } from "../../utils/portfolioUtils";
import { GraphData, NodeObject } from "react-force-graph-2d";
import { ForceGraph3D } from "react-force-graph";
import * as THREE from "three";

interface Props {
  graphData: GraphData;
  height?: number;
  onSelect: (nodeId: string) => void;
  selected: string | undefined;
  width?: number;
}

const PortfolioGraph3D = ({ graphData, height, onSelect, width }: Props) => {
  return (
    <ForceGraph3D
      graphData={graphData}
      height={height}
      linkDirectionalArrowLength={5}
      nodeAutoColorBy="nodeType"
      nodeLabel={(n: NodeObject) => (n as PortfolioNodeObject).name}
      nodeThreeObject={(n: NodeObject) => {
        const node = n as PortfolioNodeObject;
        const imgTexture = new THREE.TextureLoader().load(
          node.imageFull ?? node.image
        );
        const material = new THREE.SpriteMaterial({ map: imgTexture });
        const sprite = new THREE.Sprite(material);
        sprite.scale.set(12, 12, 0);

        return sprite;
      }}
      onNodeClick={(n: NodeObject) => {
        const node = n as PortfolioNodeObject;
        onSelect(node.id);
      }}
      onNodeDragEnd={(node: NodeObject) => {
        node.fx = node.x;
        node.fy = node.y;
      }}
      width={width}
    />
  );
};

export default PortfolioGraph3D;
