/* eslint-disable react-hooks/exhaustive-deps */
import { forceCollide } from "d3-force";
import { MutableRefObject, useEffect, useState } from "react";
import { ForceGraphMethods, GraphData, NodeObject } from "react-force-graph-2d";
import { ForceGraph2D } from "react-force-graph";
import { MantineTheme, useMantineTheme } from "@mantine/core";
import {
  PortfolioNodeType,
  PortfolioNodeObject,
} from "../../utils/portfolioUtils";

/** Size of node image */
const imageSize = 24;

/** Max length of node labels shown below image, doesn't not apply to hover label */
const maxLabelLength = 20;

/**
 * Set content (image and text) for node based on type
 * @param n Node object
 * @param ctx Canvas contact
 * @param globalScale Global scale value
 * @param selected Flag indicating node has been selected
 * @param theme Theme granting access to dark/light mode palette
 * @param imageMap Cache of images to prevent reloading of images which cause flickering
 */
const setContentForNode = (
  n: NodeObject,
  ctx: CanvasRenderingContext2D,
  globalScale: number,
  selected: boolean,
  theme: MantineTheme,
  imageMap: Map<string, HTMLImageElement>
) => {
  const node = n as PortfolioNodeObject;
  const scaleImageSize = imageSize;

  const fontSize = 10 / globalScale;
  const labelText = node.shortName ?? node.name;
  const label =
    labelText.length < maxLabelLength
      ? labelText
      : `${labelText.slice(0, maxLabelLength)}...`;
  const x = node.x ?? 0;
  const y = node.y ?? 0;

  if (node.opacity !== undefined) {
    ctx.globalAlpha = node.opacity;
  }

  // Image
  if (globalScale > 0.3) {
    let img = imageMap.get(node.id);
    if (img) {
      try {
        ctx.drawImage(
          img,
          x - scaleImageSize / 2,
          y - scaleImageSize / 2,
          scaleImageSize,
          scaleImageSize
        );
      } catch (error) {
        console.error("Failed to draw image for node", node);
      }
    }
  }

  // Text
  let textPaddingTop = 0;
  ctx.font = `${fontSize}px Sans-Serif`;
  ctx.textAlign = "center";
  ctx.textBaseline = "bottom";

  switch (node.nodeType) {
    case PortfolioNodeType.clients:
    case PortfolioNodeType.platforms:
      textPaddingTop = fontSize;
      ctx.font = `bold ${Math.floor(fontSize * 1.75)}px Sans-Serif`;
      break;
    case PortfolioNodeType.projects:
      textPaddingTop = Math.floor(fontSize / 2);
      ctx.font = `${Math.floor(fontSize * 1.5)}px Sans-Serif`;
      break;
    case PortfolioNodeType.languages:
    case PortfolioNodeType.technologies:
      break;
  }
  ctx.fillText(label, x, y + scaleImageSize + textPaddingTop);

  // Theme
  ctx.fillStyle = theme.colorScheme === "dark" ? "white" : "black";

  // Highlight selected
  if (selected) {
    const padding = 10;
    const width =
      Math.max(ctx.measureText(label).width, scaleImageSize) + padding * 2;
    const height = 50 + textPaddingTop + padding;
    ctx.lineWidth = 4;
    ctx.strokeStyle = theme.primaryColor;
    ctx.strokeRect(x - width / 2, y - height / 2, width, height);
  }
};

const setForces = (fg: ForceGraphMethods | undefined, nodes: NodeObject[]) => {
  if (!fg) {
    return;
  }

  fg.d3Force(
    "collision",
    forceCollide(() => {
      return imageSize * 2;
    })
  );

  fg.d3Force("box", () => {
    const radius = 500;

    for (const node of nodes) {
      // Of the positions exceed the box, set them to the boundary position.
      // You may want to include your nodes width to not overlap with the box.
      node.x = Math.max(-radius, Math.min(radius, node.x ?? 0));
      node.y = Math.max(-radius, Math.min(radius, node.y ?? 0));
    }
  });

  const links = fg.d3Force("link");
  if (links) {
    links.distance(() => 20);
  }
};

interface Props {
  graphData: GraphData;
  graphRef: MutableRefObject<ForceGraphMethods | undefined>;
  height?: number;
  onSelect: (nodeId: string) => void;
  selected: string | undefined;
  width?: number;
}

const PortfolioGraph2D = ({
  graphData,
  graphRef,
  height,
  onSelect,
  selected,
  width,
}: Props) => {
  const theme = useMantineTheme();
  const [imageMap, setImageMap] = useState<Map<string, HTMLImageElement>>(
    new Map()
  );

  useEffect(() => {
    const map = imageMap;
    graphData.nodes.forEach((n) => {
      const node = n as PortfolioNodeObject;

      if (!map.get(node.id)) {
        const img = new Image();
        img.src = node.image;
        map.set(node.id, img);
      }
    });

    setImageMap(map);
  }, [graphData]);

  useEffect(() => {
    setForces(graphRef?.current, graphData.nodes);
  }, [graphRef, graphData.nodes]);

  return (
    <ForceGraph2D
      graphData={graphData}
      height={height}
      linkColor={() => (theme.colorScheme === "dark" ? "#9d9b9b" : "#e3e3e3")}
      linkDirectionalArrowLength={5}
      nodeAutoColorBy="nodeType"
      nodeCanvasObject={(n, ctx, g) => {
        const isSelected =
          !!selected && (n as PortfolioNodeObject).id === selected;
        return setContentForNode(n, ctx, g, isSelected, theme, imageMap);
      }}
      nodeLabel={(n: NodeObject) => (n as PortfolioNodeObject).name}
      nodeVal={(n: NodeObject) => {
        (n as { r: number }).r = 50;
        return imageSize + 4;
      }} // Magic number with gap around image
      onNodeDragEnd={(node: NodeObject) => {
        node.fx = node.x;
        node.fy = node.y;
      }}
      onNodeClick={(n: NodeObject) => {
        const node = n as PortfolioNodeObject;
        onSelect(node.id);
      }}
      ref={graphRef}
      width={width}
    />
  );
};

export default PortfolioGraph2D;
