import { cloneDeep } from "lodash";
import { Client, PortfolioNode } from "../data/StaticPortfolioTypes";
import { clientHasText, PortfolioNodeType } from "./portfolioUtils";

export interface PortfolioNodeOption extends PortfolioNode {
  children: PortfolioNodeOption[];
  endDate?: Date;
  startDate?: Date;
}

const updateDates = (node: PortfolioNodeOption, client: Client) => {
  if (node.startDate && client.startDate && node.startDate > client.startDate) {
    node.startDate = client.startDate;
  }
  if (client.endDate && (!node.endDate || node.endDate < client.endDate)) {
    node.endDate = client.endDate;
  }
};

export const getClientAndProjectNodes = (
  clients: Client[]
): PortfolioNodeOption[] => {
  return clients.reduce<PortfolioNodeOption[]>((options, client) => {
    return [
      ...options,
      {
        ...client,
        children: getProjectsAndServiceNodes([client]),
      },
    ];
  }, []);
};

export const getLanguagesAndTechnologies = (
  clients: Client[]
): PortfolioNodeOption[] => {
  return clients.reduce<PortfolioNodeOption[]>((options, client) => {
    client.projects.forEach((project) => {
      project.technologies.forEach((tech) => {
        if (tech.languages?.length) {
          tech.languages?.forEach((language) => {
            const existingLanguage = options.find((o) => o.id === language.id);
            if (existingLanguage) {
              updateDates(existingLanguage, client);

              const existingTech = existingLanguage.children.find(
                (o) => o.id === tech.id
              );
              if (!existingTech) {
                existingLanguage.children.push({
                  ...tech,
                  endDate: client.endDate,
                  startDate: client.startDate,
                  children: [
                    {
                      ...project,
                      endDate: client.endDate,
                      startDate: client.startDate,
                      children: [],
                    },
                  ],
                });
              } else {
                updateDates(existingTech, client);
                existingTech.children.push({
                  ...project,
                  endDate: client.endDate,
                  startDate: client.startDate,
                  children: [],
                });
              }
            } else {
              options.push({
                ...language,
                endDate: client.endDate,
                startDate: client.startDate,
                children: [
                  {
                    ...tech,
                    children: [],
                  },
                ],
              });
            }
          });
        }
      });
    });
    return options;
  }, []);
};

export const getPlatformAndServiceNodes = (
  clients: Client[]
): PortfolioNodeOption[] => {
  return clients.reduce<PortfolioNodeOption[]>((options, client) => {
    client.projects.forEach((project) => {
      const projectOption = {
        ...project,
        endDate: client.endDate,
        startDate: client.startDate,
        children: [],
      };
      project.technologies.forEach((tech) => {
        if (!tech.platform) {
          return;
        }
        const existingPlatform = options.find(
          (o) => tech.platform != null && o.id === tech.platform.id
        );

        if (existingPlatform) {
          updateDates(existingPlatform, client);

          const existingTech = existingPlatform.children.find(
            (o) => o.id === tech.id
          );
          if (!existingTech) {
            existingPlatform.children.push({
              ...tech,
              endDate: client.endDate,
              startDate: client.startDate,
              children: [projectOption],
            });
          } else {
            if (
              existingTech.startDate &&
              client.startDate &&
              existingTech.startDate > client.startDate
            ) {
              existingTech.startDate = client.startDate;
            }
            if (
              existingTech.endDate &&
              client.endDate &&
              existingTech.endDate > client.endDate
            ) {
              existingTech.endDate = client.endDate;
            }
            existingTech.children.push(projectOption);
          }
        } else {
          options.push({
            ...tech.platform,
            endDate: client.endDate,
            startDate: client.startDate,
            children: [{ ...tech, children: [projectOption] }],
          });
        }
      });
    });
    return options;
  }, []);
};

export const getProjectsAndServiceNodes = (
  clients: Client[]
): PortfolioNodeOption[] => {
  return clients.reduce<PortfolioNodeOption[]>((options, client) => {
    return [
      ...options,
      ...client.projects.map((p) => ({
        ...p,
        endDate: client.endDate,
        startDate: client.startDate,
        children: p.technologies.map((t) => ({ ...t, children: [] })),
      })),
    ];
  }, []);
};

export const getTechnologyAndProjectNodes = (
  clients: Client[]
): PortfolioNodeOption[] => {
  return clients.reduce<PortfolioNodeOption[]>((options, client) => {
    client.projects.forEach((project) => {
      project.technologies.forEach((tech) => {
        const existingTech = options.find((o) => o.id === tech.id);
        if (existingTech) {
          updateDates(existingTech, client);
          if (!existingTech.children.some((o) => o.id === project.id)) {
            existingTech.children.push({
              ...project,
              endDate: client.endDate,
              startDate: client.startDate,
              children: [],
            });
          }
        } else {
          options.push({
            ...tech,
            endDate: client.endDate,
            startDate: client.startDate,
            children: [
              {
                ...project,
                endDate: client.endDate,
                startDate: client.startDate,
                children: [],
              },
            ],
          });
        }
      });
    });
    return options;
  }, []);
};

export const getNodeOptionsFromClientByType = (
  clients: Client[],
  searchText: string,
  nodeType: PortfolioNodeType
): PortfolioNodeOption[] => {
  const cloned = cloneDeep(clients).filter((c) => clientHasText(c, searchText));
  let options = [];
  switch (nodeType) {
    case PortfolioNodeType.clients:
      options = getClientAndProjectNodes(cloned);
      break;
    case PortfolioNodeType.languages:
      options = getLanguagesAndTechnologies(cloned);
      break;
    case PortfolioNodeType.platforms:
      options = getPlatformAndServiceNodes(cloned);
      break;
    case PortfolioNodeType.projects:
      options = getProjectsAndServiceNodes(cloned);
      break;
    case PortfolioNodeType.technologies:
      options = getTechnologyAndProjectNodes(cloned);
      break;
    default:
      console.error(`Extract function not defined for node type '${nodeType}'`);
      options = getClientAndProjectNodes(cloned);
  }

  options = sortNodes(options)
  const text = searchText.trim().toLowerCase();
  if (text) {
    const includeNode = (node: PortfolioNodeOption): boolean => {
      try {
        node.children = node.children.filter(includeNode);
        return nodeHasText(node, text) || !!node.children.length;
      } catch (error) {
        console.error(
          `Failed to identify whether to include node: ${node?.name}`,
          node,
          error
        );
      }
      return false;
    };
    options = options.filter(includeNode);
  }
  return options;
};

export const getNodeOptionsFromClientByTypeWithDate = (
  clients: Client[],
  searchText: string,
  nodeType: PortfolioNodeType
): PortfolioNodeOption[] => {
  const cloned = cloneDeep(clients).filter((c) => clientHasText(c, searchText));
  let options = [];
  switch (nodeType) {
    case PortfolioNodeType.clients:
      options = getClientAndProjectNodes(cloned);
      break;
    case PortfolioNodeType.languages:
      options = getLanguagesAndTechnologies(cloned);
      break;
    case PortfolioNodeType.platforms:
      options = getPlatformAndServiceNodes(cloned);
      break;
    case PortfolioNodeType.projects:
      options = getProjectsAndServiceNodes(cloned);
      break;
    case PortfolioNodeType.technologies:
      options = getTechnologyAndProjectNodes(cloned);
      break;
    default:
      console.error(`Extract function not defined for node type '${nodeType}'`);
      options = getClientAndProjectNodes(cloned);
  }

  options = sortNodes(options);
  const text = searchText.trim().toLowerCase();
  if (text) {
    const includeNode = (node: PortfolioNodeOption): boolean => {
      try {
        node.children = node.children.filter(includeNode);
        return nodeHasText(node, text) || !!node.children.length;
      } catch (error) {
        console.error(
          `Failed to identify whether to include node: ${node?.name}`,
          node,
          error
        );
      }
      return false;
    };
    options = options.filter(includeNode);
  }
  return options;
};

const nodeHasText = (node: PortfolioNodeOption, text: string): boolean =>
  node.name.toLowerCase().includes(text) ||
  !!node.keywords?.some((k) => k?.toLowerCase()?.includes(text)) ||
  !!node.shortName?.toLowerCase().includes(text);

export const portfolioPortfolioNodeOptionComparator = (
  a: PortfolioNode,
  b: PortfolioNode
) =>
  (a.shortName ?? a.name).toLowerCase() > (b.shortName ?? b.name).toLowerCase()
    ? 1
    : -1;

export const sortNodes = (nodes: PortfolioNodeOption[]) => {
  nodes.forEach((n) => (n.children = sortNodes(n.children)));
  return nodes.sort(portfolioPortfolioNodeOptionComparator);
};
