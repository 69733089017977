import { Card, createStyles } from "@mantine/core";

const useStyles = createStyles(() => ({
  container: {
    background: "#e83429",
    color: "white",
    margin: "0 auto",
    padding: "0 0 20px 0 !important",
    textAlign: "center",
    width: 300,
  },
  heading: {
    fontSize: "3em",
    fontWeight: "bold",
    lineHeight: "1.25em",
    textTransform: "uppercase",
  },
  subHeading: {
    fontSize: "1.5em",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  textContainer: {
    background: "white",
    color: "black",
    fontSize: "4em",
    lineHeight: "1em",
    padding: 10,
  },
  text: {
    fontFamily: "PoorStory, sans-serif",
    transform: "rotate(-10deg)",
  },
}));

interface Props {
  text: string;
}

const HelloMyNameIsSticker = ({ text }: Props) => {
  const { classes } = useStyles();

  return (
    <Card
      className={classes.container}
      p="lg"
      radius="md"
      shadow="sm"
      withBorder
    >
      <div className={classes.heading}>Hello</div>
      <div className={classes.subHeading}>My name is</div>
      <div className={classes.textContainer}>
        <div className={classes.text}>{text}</div>
      </div>
    </Card>
  );
};

export default HelloMyNameIsSticker;
