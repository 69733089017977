import { Client, TechnologyType } from "../../data/StaticPortfolioTypes";
import {
  PortfolioFilter,
  PortfolioGraphViewType,
  PortfolioNodeType,
} from "../../utils/portfolioUtils";
import LanguageSelect from "./LanguageSelect";
import { Button, Group, MultiSelect, TextInput } from "@mantine/core";
import PortfolioGraphViewOptions from "./PortfolioGraphViewOptions";

interface Props {
  clearFilter: () => void;
  clients: Client[];
  filter: PortfolioFilter;
  graphView: PortfolioGraphViewType;
  setFilter: (f: PortfolioFilter) => void;
  setGraphView: (v: PortfolioGraphViewType) => void;
}

const width = 200;

const PortfolioForm = ({
  clearFilter,
  clients,
  filter,
  graphView,
  setFilter,
  setGraphView,
}: Props) => {
  return (
    <Group align="start">
      <TextInput
        label="Search"
        onChange={(e) => setFilter({ ...filter, text: e.target.value })}
        placeholder="Search for item"
        value={filter.text}
      />

      <LanguageSelect
        clients={clients}
        selected={filter.languages}
        setSelected={(languages) => setFilter({ ...filter, languages })}
        width={width}
      />

      <MultiSelect
        data={Object.values(TechnologyType).filter(
          (t) => t !== TechnologyType.Language
        )}
        label="Technology layers"
        multiple
        placeholder="Select technology layers"
        onChange={(technologyTypes) =>
          setFilter({ ...filter, technologyTypes })
        }
        sx={{ width }}
        value={filter.technologyTypes}
      />
      <MultiSelect
        data={Object.values(PortfolioNodeType)}
        label="Node types"
        multiple
        placeholder="Select node types"
        onChange={(nodeTypes) => setFilter({ ...filter, nodeTypes })}
        sx={{ width }}
        value={filter.nodeTypes}
      />
      <Group mt={24}>
        <PortfolioGraphViewOptions
          setValue={(v) => setGraphView(v as PortfolioGraphViewType)}
          value={graphView}
        />
      </Group>
      <Group mt={25}>
        <Button onClick={clearFilter} sx={{ height: 36 }}>
          Reset
        </Button>
      </Group>
    </Group>
  );
};

export default PortfolioForm;
