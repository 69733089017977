import { Global } from "@mantine/core";
import poorStoryRegular from "./font/PoorStory-Regular.woff";

const CustomFonts = () => {
  return (
    <Global
      styles={[
        {
          "@font-face": {
            fontFamily: "PoorStory",
            src: `url('${poorStoryRegular}') format("woff")`,
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
      ]}
    />
  );
};

export default CustomFonts;
