/* eslint-disable react-hooks/exhaustive-deps */
import { createStyles, Grid, Stack } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ForceGraphMethods, GraphData } from "react-force-graph-2d";
import { HEADER_HEIGHT } from "../components/Layout/AppHeader";
import PortfolioForm from "../components/Portfolio/PortfolioForm";
import PortfolioGraph2D from "../components/Portfolio/PortfolioGraph2D";
import PortfolioGraph3D from "../components/Portfolio/PortfolioGraph3D";
import PortfolioNodeDetails from "../components/Portfolio/PortfolioNodeDetails";
import PortfolioTimeline from "../components/Portfolio/PortfolioTimeline";
import PortfolioTreeView from "../components/Portfolio/PortfolioTreeView";
import { clients } from "../data/StaticPortfolioData";
import { Client, PortfolioNode } from "../data/StaticPortfolioTypes";
import {
  applyClientFilter,
  clientsToGraphData,
  emptyPortfolioFilter,
  flattenClientsToAllNodes,
  PortfolioFilter,
  PortfolioGraphViewType,
  portfolioNodeNameComparator,
} from "../utils/portfolioUtils";

const useStyles = createStyles(() => ({
  fullHeight: {
    height: "100%",
  },
  graphContainer: {
    height: "100%",
    minHeight: 300,
    minWidth: 300,
  },
  stack: { height: `calc(100% - ${HEADER_HEIGHT}px)` },
}));

const PortfolioPage = () => {
  const data = clients.sort(portfolioNodeNameComparator).map((c) => {
    c.projects = c.projects.sort(portfolioNodeNameComparator);
    return c;
  });

  return <PortfolioLayout clients={data} />;
};

const PortfolioLayout = ({ clients }: { clients: Client[] }) => {
  const [filter, setFilter] = useLocalStorage<PortfolioFilter>({
    key: "portfolioFilter",
    defaultValue: emptyPortfolioFilter(),
  });
  const [graphView, setGraphView] = useLocalStorage<PortfolioGraphViewType>({
    key: "portfolioView",
    defaultValue: "Timeline",
  });

  const [graphData, setGraphData] = useState<GraphData>({
    nodes: [],
    links: [],
  });
  const [selectedNode, setSelectedNode] = useState<PortfolioNode | undefined>();
  const [loading, setLoading] = useState(true);

  const allNodes = useMemo(() => flattenClientsToAllNodes(clients), [clients]);

  const fgRef = useRef<ForceGraphMethods | undefined>();
  const graphRef = useRef<HTMLDivElement>(null);

  const { classes } = useStyles();

  useEffect(() => {
    debounceApplyFilter(clients, filter, selectedNode);
  }, [clients, graphView, filter, selectedNode]);

  const clearFilter = () => {
    setFilter(emptyPortfolioFilter());
    setSelectedNode(undefined);
  };

  const debounceApplyFilter = useCallback(
    debounce(
      (c: Client[], f: PortfolioFilter, s: PortfolioNode | undefined) => {
        const filteredClients = applyClientFilter(c, f, s);
        const data = clientsToGraphData(filteredClients, f);
        setGraphData(data);

        // Set loading flag to shrink the graph size to allow parent div to resize
        // as the graph will fill the space
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 100);
      },
      300
    ),
    []
  );

  const handleNodeSelection = (id: string | undefined) => {
    if (!id) {
      setSelectedNode(undefined);
      return;
    }
    const node = allNodes.find((n) => n.id === id);
    setSelectedNode(node);
  };

  return (
    <Stack className={classes.stack} p={20}>
      <PortfolioForm
        clearFilter={clearFilter}
        clients={clients}
        filter={filter}
        graphView={graphView}
        setFilter={setFilter}
        setGraphView={setGraphView}
      />
      {graphView === "Timeline" ? (
        <PortfolioTimeline clients={clients} searchText={filter.text} />
      ) : (
        <Grid grow className={classes.fullHeight}>
          <Grid.Col sx={{ flex: 0 }}>
            <PortfolioTreeView
              clients={clients}
              searchText={filter.text}
              selected={selectedNode?.id}
              setSelected={handleNodeSelection}
            />
          </Grid.Col>
          {selectedNode && (
            <Grid.Col sx={{ flex: 0 }}>
              <PortfolioNodeDetails
                clearSelection={() => setSelectedNode(undefined)}
                node={selectedNode}
                showAllNodes={() => {
                  setFilter(emptyPortfolioFilter());
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col span="auto" xs="content">
            <div className={classes.graphContainer} ref={graphRef}>
              <>
                {graphView === "2D" && (
                  <PortfolioGraph2D
                    graphData={graphData}
                    graphRef={fgRef}
                    height={loading ? 1 : graphRef.current?.clientHeight}
                    onSelect={handleNodeSelection}
                    selected={selectedNode?.id}
                    width={loading ? 1 : graphRef.current?.clientWidth}
                  />
                )}
                {graphView === "3D" && (
                  <PortfolioGraph3D
                    graphData={graphData}
                    height={loading ? 1 : graphRef.current?.clientHeight}
                    onSelect={handleNodeSelection}
                    selected={selectedNode?.id}
                    width={loading ? 1 : graphRef.current?.clientWidth}
                  />
                )}
              </>
            </div>
          </Grid.Col>
        </Grid>
      )}
    </Stack>
  );
};

export default PortfolioPage;
