import { Badge, Grid, Space, Stack, Text } from "@mantine/core";
import {
  IconChevronDown,
  IconChevronRight,
  IconEye,
  IconEyeOff,
} from "@tabler/icons";
import { useMemo } from "react";
import { PortfolioNodeOption } from "../../utils/portfolioTreeUtils";

interface Props {
  expanded: string | undefined;
  node: PortfolioNodeOption;
  onSelectNode: (nodeId: string | undefined) => void;
  selectedNode: string | undefined;
  setExpanded: (nodeId: string | undefined) => void;
}

const isNodeOrChildrenExpanded = (
  node: PortfolioNodeOption,
  selected: string | undefined
): boolean => {
  if (!selected) {
    return false;
  }
  if (selected === node.id) {
    return true;
  }
  if (!node.children?.length) {
    return false;
  }
  return node.children.some((n) => isNodeOrChildrenExpanded(n, selected));
};

const PortfolioTreeItem = ({
  expanded,
  node,
  onSelectNode,
  selectedNode,
  setExpanded,
}: Props) => {
  const selected = node.id === selectedNode;

  const isExpanded = useMemo(
    () => isNodeOrChildrenExpanded(node, expanded),
    [expanded, node]
  );

  return (
    <div>
      <Grid align="center" gutter={20}>
        {!node.children?.length ? (
          <Space sx={{ width: 24 }} />
        ) : isExpanded ? (
          <IconChevronDown onClick={() => setExpanded(undefined)} />
        ) : (
          <IconChevronRight onClick={() => setExpanded(node.id)} />
        )}

        <img
          alt={`${node.name} logo`}
          height={24}
          src={node.image}
          width={24}
        />
        <Grid.Col span="auto">
          <Text sx={{ display: "inline-block" }} weight={600}>
            {node.shortName ?? node.name}
          </Text>
          {!!node.children?.length && (
            <Badge ml={10} size="sm">
              {node.children.length}
            </Badge>
          )}
        </Grid.Col>
        {selected ? (
          <IconEyeOff
            onClick={(e: any) => {
              e?.stopPropagation();
              onSelectNode(undefined);
            }}
          />
        ) : (
          <IconEye
            onClick={(e: any) => {
              e?.stopPropagation();
              onSelectNode(node.id);
            }}
            style={{ opacity: 0.3 }}
          />
        )}
      </Grid>
      {isExpanded && (
        <Stack mb={10} ml={24} mt={10}>
          {node.children?.map((c) => (
            <PortfolioTreeItem
              expanded={expanded}
              key={c.id}
              node={c}
              onSelectNode={onSelectNode}
              selectedNode={selectedNode}
              setExpanded={setExpanded}
            />
          ))}
        </Stack>
      )}
    </div>
  );
};

export default PortfolioTreeItem;
