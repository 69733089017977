import {
  Client,
  Industries,
  Language,
  PortfolioNode,
  Technology,
  TechnologyType,
} from "./StaticPortfolioTypes";

const commonKeywords = {
  databaseMigration: "Database migration",
  staticCodeAnalysis: "Static code analysis",
};

export const cloudPlatforms: { [key: string]: PortfolioNode } = {
  Aws: {
    id: "AWS",
    image: "/images/aws.png",
    keywords: ["AWS", "Amazon Web Services"],
    name: "AWS",
  },
  Azure: {
    id: "Azure",
    image: "/images/azure.png",
    keywords: ["Azure", "Microsoft"],
    name: "Azure",
  },
  Firebase: {
    id: "Firebase",
    image: "/images/firebase.png",
    keywords: ["Google"],
    name: "Firebase",
    url: "https://firebase.google.com",
  },
  GCP: {
    id: "GCP",
    image: "/images/gcp.png",
    keywords: ["GCP", "Google"],
    name: "Google Cloud Platform",
    url: "https://cloud.google.com/products/",
  },
  GitHub: {
    id: "GitHub",
    image: "/images/github.png",
    keywords: ["GitHub"],
    name: "GitHub",
    url: "https://github.com",
  },
};

export const languages: { [key: string]: Language } = {
  csharp: {
    id: "csharp",
    image: "/images/csharp.png",
    keywords: ["C#", ".NET"],
    name: "C#",
    type: TechnologyType.Language,
  },
  groovy: {
    id: "groovy",
    image: "/images/groovy.png",
    keywords: ["Groovy", "Java", "JVM"],
    name: "Groovy",
    type: TechnologyType.Language,
  },
  java: {
    id: "java",
    image: "/images/java.png",
    keywords: ["Java", "JVM"],
    name: "Java",
    type: TechnologyType.Language,
  },
  javascript: {
    id: "javascript",
    image: "/images/javascript.png",
    keywords: ["ECMA"],
    name: "JavaScript",
    type: TechnologyType.Language,
  },
  typescript: {
    id: "typescript",
    image: "/images/typescript.png",
    keywords: ["JavaScript"],
    name: "TypeScript",
    type: TechnologyType.Language,
  },
  sql: {
    id: "sql",
    image: "/images/sql.png",
    keywords: ["SQL"],
    name: "SQL",
    type: TechnologyType.Language,
  },
  xml: {
    id: "xml",
    image: "/images/xml.png",
    keywords: ["markup"],
    name: "XML",
    type: TechnologyType.Language,
  },
  vb: {
    id: "vb",
    image: "/images/vb.png",
    keywords: ["vb"],
    name: "Visual Basic",
    type: TechnologyType.Language,
  },
  xaml: {
    id: "xaml",
    image: "/images/xaml.png",
    keywords: ["markup"],
    name: "XAML",
    type: TechnologyType.Language,
  },
  yaml: {
    id: "yaml",
    image: "/images/yaml.png",
    keywords: ["markup"],
    name: "YAML",
    type: TechnologyType.Language,
  },
};

const ciAndCd = [
  "build",
  "CD",
  "Continuous delivery",
  "CI",
  "Continuous integration",
];

export const tech: { [key: string]: Technology } = {
  ActiveDirectory: {
    id: "ActiveDirectory",
    image: "/images/active_directory.png",
    keywords: ["LDAP"],
    name: "Active Directory",
    types: [TechnologyType.DevOps],
  },
  AppCenter: {
    id: "AppCenter",
    image: "/images/appcenter.png",
    keywords: ["Microsoft App Center"],
    name: "App Center",
    types: [TechnologyType.DevOps],
  },
  Angular: {
    id: "Angular",
    image: "/images/angular.png",
    languages: [languages.typescript],
    name: "Angular",
    types: [TechnologyType.Frontend],
    url: "https://angular.io/",
  },
  Ant: {
    id: "Ant",
    image: "/images/ant.png",
    name: "Ant",
    types: [TechnologyType.Backend],
    url: "https://ant.apache.org/",
  },
  Artifactory: {
    id: "Artifactory",
    image: "/images/artifactory.png",
    name: "Artifactory",
    types: [TechnologyType.DevOps],
  },
  AspNet: {
    id: "AspNet",
    image: "/images/dotnet.png",
    keywords: ["aspnet", "Microsoft", ".NET"],
    languages: [languages.csharp],
    name: "ASP.NET",
    types: [TechnologyType.Backend],
  },
  AspNetMvc: {
    id: "AspNet",
    image: "/images/dotnet.png",
    keywords: ["aspnet", "Microsoft", ".NET"],
    languages: [languages.csharp],
    name: "ASP.NET MVC",
    types: [TechnologyType.Backend],
  },
  AspNetCore: {
    id: "AspNetCore",
    image: "/images/net_core.png",
    keywords: ["aspnet", "Microsoft", ".NET"],
    languages: [languages.csharp],
    name: "ASP.NET Core MVC",
    types: [TechnologyType.Backend],
  },
  AtlassianBamboo: {
    id: "AtlassianBamboo",
    image: "/images/atlassian_bamboo.png",
    keywords: ["Atlassian", ...ciAndCd],
    name: "Bamboo",
    types: [TechnologyType.DevOps],
  },
  AtlassianConfluence: {
    id: "AtlassianConfluence",
    image: "/images/atlassian_confluence.png",
    keywords: ["Atlassian", "Wiki"],
    name: "Confluence",
    types: [TechnologyType.KnowledgeSharing],
  },
  AtlassianCrucible: {
    id: "AtlassianCrucible",
    image: "/images/atlassian_crucible.png",
    keywords: ["Atlassian"],
    name: "Crucible",
    types: [TechnologyType.DevOps],
  },
  AtlassianJira: {
    id: "AtlassianJira",
    image: "/images/atlassian_jira.png",
    keywords: ["Atlassian", "Bug", "Issue", "Jira"],
    name: "Jira",
    types: [TechnologyType.DevOps],
  },
  AwsApiGateway: {
    id: "AwsApiGateway",
    image: "/images/aws_api_gateway.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? []), "REST", "Swagger"],
    name: "API Gateway",
    platform: cloudPlatforms.Aws,
    types: [TechnologyType.Service],
    url: "https://aws.amazon.com/api-gateway",
  },
  AwsCloudFront: {
    id: "AwsCloudFront",
    image: "/images/aws_cloudfront.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? [])],
    name: "CloudFront",
    platform: cloudPlatforms.Aws,
    types: [TechnologyType.Service],
    url: "https://aws.amazon.com/cloudfront",
  },
  AwsCloudWatch: {
    id: "AwsCloudWatch",
    image: "/images/aws_cloudwatch.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? [])],
    name: "CloudWatch",
    platform: cloudPlatforms.Aws,
    types: [
      TechnologyType.DevOps,
      TechnologyType.Service,
      TechnologyType.Logging,
    ],
    url: "https://aws.amazon.com/cloudwatch",
  },
  AwsCognito: {
    id: "AwsCognito",
    image: "/images/aws_cognito.png",
    keywords: [
      ...(cloudPlatforms.Aws.keywords ?? []),
      "auth",
      "oauth",
      "identity",
    ],
    name: "Cognito",
    platform: cloudPlatforms.Aws,
    types: [TechnologyType.Service],
    url: "https://aws.amazon.com/cognito",
  },
  AwsDynamoDB: {
    id: "AwsDynamoDB",
    image: "/images/aws_dynamodb.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? []), "DynamoDB"],
    name: "DynamoDB",
    platform: cloudPlatforms.Aws,
    types: [TechnologyType.Service, TechnologyType.Database],
    url: "https://aws.amazon.com/dynamodb",
  },
  AwsEcr: {
    id: "AwsEcr",
    image: "/images/aws_ecr.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? []), "Docker", "ECR"],
    name: "Elastic Container Registry",
    platform: cloudPlatforms.Aws,
    shortName: "ECR",
    types: [TechnologyType.Service],
    url: "https://aws.amazon.com/ec2",
  },
  AwsEcs: {
    id: "AwsEcs",
    image: "/images/aws_ecs.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? []), "Docker", "ECS"],
    name: "Elastic Container Service",
    platform: cloudPlatforms.Aws,
    shortName: "ECS",
    types: [TechnologyType.Service],
    url: "https://aws.amazon.com/ecs",
  },
  AwsEc2: {
    id: "AwsEc2",
    image: "/images/aws_ec2.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? []), "EC2"],
    name: "Elastic Cloud Computing",
    platform: cloudPlatforms.Aws,
    shortName: "EC2",
    types: [TechnologyType.Service],
    url: "https://aws.amazon.com/ec2",
  },
  AwsLambda: {
    id: "AwsLambda",
    image: "/images/aws_lambda.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? []), "lambda", "serverless"],
    name: "Lambda",
    platform: cloudPlatforms.Aws,
    types: [TechnologyType.Service],
    url: "https://aws.amazon.com/lambda",
  },
  AwsMediaConvert: {
    id: "AwsMediaConvert",
    image: "/images/aws_media_convert.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? []), "video", "transcode"],
    name: "Media Convert",
    platform: cloudPlatforms.Aws,
    types: [TechnologyType.Service],
    url: "https://aws.amazon.com/mediaconvert",
  },
  AwsRds: {
    id: "AwsRds",
    image: "/images/aws_rds.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? []), "postgresql", "RDS"],
    languages: [languages.sql],
    name: "Relational Database Service",
    platform: cloudPlatforms.Aws,
    shortName: "RDS",
    types: [TechnologyType.Database],
    url: "https://aws.amazon.com/rds",
  },
  AwsSns: {
    id: "AwsSns",
    image: "/images/aws_sns.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? [])],
    name: "Simple Notification Service",
    platform: cloudPlatforms.Aws,
    shortName: "SNS",
    types: [TechnologyType.Service],
    url: "https://aws.amazon.com/sns",
  },
  AwsSqs: {
    id: "AwsSqs",
    image: "/images/aws_sqs.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? [])],
    name: "Simple Queue Service",
    platform: cloudPlatforms.Aws,
    shortName: "SQS",
    types: [TechnologyType.Service],
    url: "https://aws.amazon.com/sqs",
  },
  AwsS3: {
    id: "AwsS3",
    image: "/images/aws_s3.png",
    keywords: [...(cloudPlatforms.Aws.keywords ?? [])],
    name: "Simple Cloud Storage",
    platform: cloudPlatforms.Aws,
    shortName: "S3",
    types: [TechnologyType.Service],
    url: "https://aws.amazon.com/s3",
  },
  AzureAD: {
    id: "AzureAD",
    image: "/images/azure_ad.png",
    keywords: [
      ...(cloudPlatforms.Azure.keywords ?? []),
      "AD",
      "Activity Directory",
      "Identity",
    ],
    name: "Azure Active Directory",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.DevOps],
    url: "https://azure.microsoft.com/en-au/services/active-directory",
  },
  AzureAppConfiguration: {
    id: "AzureAppConfiguration",
    image: "/images/azure_app_config.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? []), "configuration"],
    name: "App Configuration",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.DevOps],
  },
  AzureArtifacts: {
    id: "AzureArtifacts",
    image: "/images/azure_artifacts.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? []), "DevOps"],
    name: "Artifacts",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.DevOps],
    url: "https://azure.microsoft.com/en-au/services/devops/artifacts/",
  },
  AzureB2C: {
    id: "AzureB2C",
    image: "/images/azure_b2c.png",
    keywords: [
      ...(cloudPlatforms.Azure.keywords ?? []),
      "auth",
      "oauth",
      "identity",
    ],
    name: "B2C",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.Service],
  },
  AzureBlobStorage: {
    id: "AzureBlobStorage",
    image: "/images/azure_blob_storage.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? [])],
    name: "Blob Storage",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.Service],
  },
  AzureBoards: {
    id: "AzureBoards",
    image: "/images/azure_boards.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? []), "DevOps", "Git"],
    name: "Boards",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.DevOps],
    url: "https://azure.microsoft.com/en-au/services/devops/boards/",
  },
  AzureCommsServices: {
    id: "AzureCommsServices",
    image: "/images/azure_comms_services.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? [])],
    name: "Communication Services",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.Service],
  },
  AzureCosmosDB: {
    id: "AzureCosmosDB",
    image: "/images/azure_cosmos_db.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? []), "nosql"],
    name: "Cosmos DB",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.Database],
  },
  AzureDevOps: {
    id: "AzureDevOps",
    image: "/images/azure_devops.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? []), "DevOps"],
    name: "DevOps",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.DevOps],
    url: "https://azure.microsoft.com/en-au/solutions/devops/#overview",
  },
  AzureEventGrid: {
    id: "AzureEventGrid",
    image: "/images/azure_event_grid.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? []), "Events"],
    name: "Event Grid",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.Service],
    url: "https://azure.microsoft.com/en-au/services/event-grid",
  },
  AzureFunctions: {
    id: "AzureFunctions",
    image: "/images/azure_functions.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? []), "Functions", "Lambda"],
    name: "Functions",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.Service],
    url: "https://azure.microsoft.com/en-au/services/functions",
  },
  AzureKeyVault: {
    id: "AzureKeyVault",
    image: "/images/azure_key_vault.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? []), "Configuration"],
    name: "Key Vault",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.DevOps],
    url: "https://azure.microsoft.com/en-au/services/key-vault",
  },
  AzureLogicApps: {
    id: "AzureLogicApps",
    image: "/images/azure_logic_apps.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? [])],
    name: "Logic Apps",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.Service],
    url: "https://azure.microsoft.com/en-au/services/logic-apps",
  },
  AzureNotificationHubs: {
    id: "AzureNotificationHubs",
    image: "/images/azure_notification_hubs.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? [])],
    name: "Notification Hubs",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.Service],
    url: "https://azure.microsoft.com/en-au/services/logic-apps/#overview",
  },
  AzureRepos: {
    id: "AzureRepos",
    image: "/images/azure_repos.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? []), "DevOps", "Git"],
    name: "Repositories",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.DevOps],
    url: "https://azure.microsoft.com/en-au/services/devops/repos/",
  },
  AzurePipelines: {
    id: "AzurePipelines",
    image: "/images/azure_pipelines.png",
    keywords: [...(cloudPlatforms.Azure.keywords ?? []), ...ciAndCd, "DevOps"],
    languages: [languages.yaml],
    name: "Pipelines",
    platform: cloudPlatforms.Azure,
    types: [TechnologyType.DevOps],
    url: "https://azure.microsoft.com/en-au/services/devops/pipelines/",
  },
  Blackboard: {
    id: "Blackboard",
    image: "/images/blackboard.png",
    keywords: ["LMS"],
    name: "Blackboard",
    types: [TechnologyType.Service],
  },
  Bootstrap: {
    id: "Bootstrap",
    image: "/images/bootstrap.png",
    languages: [languages.javascript],
    name: "Bootstrap",
    types: [TechnologyType.Frontend],
  },
  Brightspace: {
    id: "Brightspace",
    image: "/images/brightspace.png",
    keywords: ["LMS"],
    name: "Brightspace",
    types: [TechnologyType.Service],
  },
  Canvas: {
    id: "Canvas",
    image: "/images/canvas.png",
    name: "Canvas",
    keywords: ["LMS"],
    types: [TechnologyType.Service],
  },
  CodeNarc: {
    id: "CodeNarc",
    image: "/images/codenarc.png",
    imageFull: "/images/codenarc_full.png",
    keywords: [commonKeywords.staticCodeAnalysis],
    name: "CodeNarc",
    types: [TechnologyType.DevOps],
    url: "https://codenarc.org/",
  },
  Docker: {
    id: "Docker",
    image: "/images/docker.png",
    keywords: ["Container", "Docker"],
    name: "Docker",
    types: [TechnologyType.DevOps],
  },
  EntityFramework: {
    id: "EntityFramework",
    image: "/images/dotnet.png",
    keywords: ["EF"],
    languages: [languages.csharp],
    name: "Entity Framework",
    types: [TechnologyType.Backend],
    url: "https://docs.microsoft.com/en-us/ef/",
  },
  ESLint: {
    id: "ESLint",
    image: "/images/eslint.png",
    keywords: [commonKeywords.staticCodeAnalysis],
    languages: [languages.typescript],
    name: "ESLint",
    types: [TechnologyType.DevOps],
    url: "https://eslint.org/",
  },
  Figma: {
    id: "Figma",
    image: "/images/figma.png",
    keywords: ["Design", "Figma"],
    name: "Figma",
    types: [TechnologyType.Design],
  },
  FirebaseDynamicLinks: {
    id: "FirebaseDynamicLinks",
    image: "/images/firebase_dynamic_links.png",
    keywords: ["Google", "Firebase"],
    name: "Dynamic links",
    platform: cloudPlatforms.Firebase,
    types: [TechnologyType.Service],
  },
  FirebaseHosting: {
    id: "FirebaseDynamicLinks",
    image: "/images/firebase_hosting.png",
    keywords: ["Google", "Firebase"],
    name: "Hosting",
    platform: cloudPlatforms.Firebase,
    types: [TechnologyType.Service],
  },
  Geb: {
    id: "Geb",
    image: "/images/geb.png",
    keywords: ["functional tests", "Selenium"],
    name: "Geb",
    types: [TechnologyType.Tests],
  },
  Git: {
    id: "Git",
    image: "/images/git.png",
    name: "Git",
    types: [TechnologyType.SourceControl],
  },
  GitHub: {
    id: "GitHub",
    image: "/images/github.png",
    keywords: ["Git"],
    name: "GitHub",
    types: [TechnologyType.DevOps],
    url: "https://github.com",
  },
  GitHubActions: {
    id: "GitHubActions",
    image: "/images/github_actions.png",
    name: "GitHub Actions",
    platform: cloudPlatforms.GitHub,
    types: [TechnologyType.DevOps],
  },
  GitLab: {
    id: "GitLab",
    image: "/images/gitlab.png",
    keywords: ["GitLab", ...ciAndCd],
    languages: [languages.yaml],
    name: "GitLab",
    types: [TechnologyType.DevOps],
  },
  GlassFish: {
    id: "GlassFish",
    image: "/images/glassfish.png",
    name: "GlassFish",
    types: [TechnologyType.Backend],
  },
  Grails: {
    id: "Grails",
    image: "/images/grails.png",
    keywords: ["GORM"],
    languages: [languages.java, languages.groovy],
    name: "Grails",
    types: [TechnologyType.Backend],
  },
  Gradle: {
    id: "Gradle",
    image: "/images/gradle.png",
    keywords: ["YAML"],
    languages: [languages.yaml],
    name: "Gradle",
    types: [TechnologyType.DevOps],
  },
  GWT: {
    id: "GWT",
    image: "/images/gwt.png",
    keywords: ["Google web tookit"],
    name: "GWT",
    languages: [languages.java],
    types: [TechnologyType.Frontend],
  },
  JavaEE: {
    id: "JavaEE",
    image: "/images/java.png",
    keywords: ["Java", "JSP"],
    languages: [languages.java],
    name: "Java EE",
    types: [TechnologyType.Backend],
  },
  Jest: {
    id: "Jest",
    image: "/images/jest.png",
    keywords: ["unit tests"],
    languages: [languages.typescript],
    name: "Jest",
    types: [TechnologyType.Tests],
  },
  JetBrainsTeamCity: {
    id: "JetBrainsTeamCity",
    image: "/images/jetbrains_team_city.png",
    keywords: ["JetBrainsTeamCity", ...ciAndCd],
    name: "TeamCity",
    types: [TechnologyType.DevOps],
  },
  JQuery: {
    id: "JQuery",
    image: "/images/jquery.png",
    languages: [languages.javascript],
    keywords: ["ajax"],
    name: "jQuery",
    types: [TechnologyType.Frontend],
  },
  JQueryUI: {
    id: "JQueryUI",
    image: "/images/jquery_ui.png",
    languages: [languages.javascript],
    name: "jQuery UI",
    types: [TechnologyType.Frontend],
  },
  JUnit: {
    id: "JUnit",
    image: "/images/junit.png",
    keywords: ["unit tests", "integration tests"],
    languages: [languages.java, languages.groovy],
    name: "JUnit",
    types: [TechnologyType.Tests],
  },
  IndexedDb: {
    id: "IndexedDB",
    image: "/images/indexeddb.png",
    keywords: ["PWA"],
    languages: [languages.typescript],
    name: "IndexedDB",
    types: [TechnologyType.Database, TechnologyType.Frontend],
  },
  IIS: {
    id: "IIS",
    image: "/images/iis.png",
    name: "IIS",
    types: [TechnologyType.Backend],
  },
  Kibana: {
    id: "Kibana",
    image: "/images/kibana.png",
    name: "Kibana",
    types: [TechnologyType.DevOps],
  },
  Liquibase: {
    id: "Liquibase",
    image: "/images/liquibase.png",
    keywords: [commonKeywords.databaseMigration],
    name: "Liquibase",
    types: [TechnologyType.DevOps],
  },
  m204: {
    id: "m204",
    image: "/images/mainframe.png",
    keywords: ["Mainframe", "greenscreen", "cli"],
    name: "M204",
    types: [TechnologyType.Backend],
  },
  Mantine: {
    id: "Mantine",
    image: "/images/mantine.svg",
    keywords: ["mantine", "react"],
    languages: [languages.typescript],
    name: "Mantine",
    types: [TechnologyType.Frontend],
    url: "https://mantine.dev/",
  },
  MaterialUi: {
    id: "MaterialUi",
    image: "/images/material_ui.png",
    keywords: ["mui", "react"],
    languages: [languages.typescript],
    name: "Material UI",
    types: [TechnologyType.Frontend],
    url: "https://mui.com/",
  },
  Maven: {
    id: "Maven",
    image: "/images/maven.png",
    name: "Maven",
    types: [TechnologyType.DevOps],
  },
  Mediatr: {
    id: "Mediatr",
    image: "/images/mediatr.png",
    languages: [languages.csharp],
    name: "Mediatr",
    types: [TechnologyType.Backend],
    url: "https://github.com/jbogard/MediatR",
  },
  Micronaut: {
    id: "Micronaut",
    image: "/images/micronaut.png",
    keywords: ["Groovy", "Framework", "Micronaut"],
    languages: [languages.java, languages.groovy, languages.yaml],
    name: "Micronaut",
    types: [TechnologyType.Backend],
    url: "https://micronaut.io/",
  },
  Moodle: {
    id: "Moodle",
    image: "/images/moodle.png",
    keywords: ["LMS"],
    name: "Moodle",
    types: [TechnologyType.Service],
  },
  Moq: {
    id: "Moq",
    image: "/images/moq.png",
    keywords: ["Mocking", "Unit tests", "Integration tests"],
    languages: [languages.csharp],
    name: "Moq",
    types: [TechnologyType.Tests],
  },
  MrArchitect: {
    id: "MrArchitect",
    image: "/images/mr_architect.svg",
    languages: [languages.java],
    name: "Mr Architect",
    types: [TechnologyType.Backend, TechnologyType.Database],
  },
  MsArchitect: {
    id: "MsArchitect",
    image: "/images/ms_architect.svg",
    languages: [languages.java],
    name: "Ms Architect",
    types: [TechnologyType.Frontend],
  },
  Nagios: {
    id: "Nagios",
    image: "/images/nagios.png",
    keywords: ["Monitoring"],
    name: "Nagios",
    types: [TechnologyType.DevOps],
  },
  NetCoreCSharp: {
    id: "NetCoreCSharp",
    image: "/images/net_core.png",
    keywords: ["C#", "Framework", "Microsoft", ".NET Core"],
    languages: [languages.csharp],
    name: ".NET Core",
    types: [TechnologyType.Backend],
  },
  Nexus: {
    id: "Nexus",
    image: "/images/nexus.png",
    keywords: ["artifacts", "artefacts", "repository"],
    name: "Nexus",
    types: [TechnologyType.DevOps],
  },
  NextJs: {
    id: "NextJs",
    image: "/images/nextjs.png",
    name: "Next.js",
    types: [TechnologyType.Frontend],
  },
  NewRelic: {
    id: "NewRelic",
    image: "/images/new_relic.svg",
    keywords: ["alerts", "monitoring"],
    name: "New Relic",
    types: [TechnologyType.DevOps],
    url: "https://newrelic.com",
  },
  NLog: {
    id: "NLog",
    image: "/images/nlog.png",
    languages: [languages.csharp],
    name: "NLog",
    types: [TechnologyType.Logging],
    url: "https://nlog-project.org",
  },
  NuGet: {
    id: "NuGet",
    image: "/images/nuget.png",
    name: "NuGet",
    types: [TechnologyType.DevOps],
  },
  NUnit: {
    id: "NUnit",
    image: "/images/nunit.png",
    keywords: ["unit tests", "integration tests"],
    name: "NUnit",
    types: [TechnologyType.Tests],
  },
  OpenAPI: {
    id: "OpenAPI",
    image: "/images/openapi.png",
    keywords: ["REST", "Swagger"],
    name: "OpenAPI",
    types: [TechnologyType.Backend],
  },
  PagerDuty: {
    id: "PagerDuty",
    image: "/images/pager_duty.png",
    keywords: ["alerts", "monitoring"],
    name: "New PagerDuty",
    types: [TechnologyType.DevOps],
    url: "https://pagerduty.com",
  },
  PostgreSQL: {
    id: "PostgreSQL",
    image: "/images/postgresql.png",
    languages: [languages.sql],
    name: "PostgreSQL",
    types: [TechnologyType.Database],
    url: "https://www.postgresql.org/",
  },
  Powershell: {
    id: "Powershell",
    image: "/images/powershell.png",
    name: "Powershell",
    types: [TechnologyType.DevOps],
  },
  React: {
    id: "React",
    image: "/images/react.png",
    languages: [languages.typescript],
    name: "React",
    types: [TechnologyType.Frontend],
    url: "https://reactjs.org/",
  },
  ReactTestingLibrary: {
    id: "ReactTestingLibrary",
    image: "/images/react_testing_library.png",
    languages: [languages.typescript],
    name: "React Testing Library",
    types: [TechnologyType.Tests],
    url: "https://testing-library.com/",
  },
  Redux: {
    id: "Redux",
    image: "/images/redux.png",
    keywords: ["React"],
    languages: [languages.typescript],
    name: "Redux",
    types: [TechnologyType.Frontend],
    url: "https://redux.js.org/",
  },
  ReSharper: {
    id: "ReSharper",
    image: "/images/resharper.png",
    keywords: [commonKeywords.staticCodeAnalysis],
    name: "ReSharper",
    types: [TechnologyType.DevOps, TechnologyType.Language],
    url: "https://www.jetbrains.com/resharper/",
  },
  ShuttleEsb: {
    id: "ShuttleEsb",
    image: "/images/shuttle.svg",
    keywords: ["service bus", "event"],
    name: "Shuttle.Esb",
    types: [TechnologyType.DevOps],
    url: "https://shuttle.github.io/shuttle-esb/",
  },
  Slack: {
    id: "Slack",
    image: "/images/slack.png",
    name: "Slack",
    types: [TechnologyType.KnowledgeSharing],
  },
  Solr: {
    id: "Solr",
    image: "/images/solr.png",
    keywords: ["lucence", "nosql"],
    name: "solr",
    types: [TechnologyType.Backend, TechnologyType.Database],
  },
  Spock: {
    id: "Spock",
    image: "/images/spock.png",
    keywords: ["unit tests", "integration tests"],
    name: "Spock",
    types: [TechnologyType.Tests],
  },
  SQLServer: {
    id: "SQLServer",
    image: "/images/microsoft_sql_server.png",
    keywords: ["SQL"],
    name: "SQL Server",
    languages: [languages.sql],
    types: [TechnologyType.Database],
  },
  SSIS: {
    id: "SSIS",
    image: "/images/microsoft_sql_server.png",
    keywords: ["SQL Server Integration Services"],
    name: "SSIS",
    languages: [languages.sql],
    types: [TechnologyType.Backend],
  },
  SSRS: {
    id: "SSRS",
    image: "/images/microsoft_sql_server.png",
    keywords: ["SQL Server Reporting Services"],
    name: "SSRS",
    languages: [languages.sql, languages.vb],
    types: [TechnologyType.Backend],
  },
  Subversion: {
    id: "Subversion",
    image: "/images/subversion.png",
    name: "Subversion",
    types: [TechnologyType.SourceControl],
  },
  SumoLogic: {
    id: "SumoLogic",
    image: "/images/sumo_logic.webp",
    keywords: ["monitoring"],
    name: "Sumo Logic",
    types: [TechnologyType.DevOps, TechnologyType.Logging],
    url: "https://sumologic.com",
  },
  Storybook: {
    id: "Storybook",
    image: "/images/storybook.png",
    imageFull: "/images/storybook_full.png",
    name: "Storybook",
    types: [TechnologyType.DevOps],
    url: "https://storybook.js.org/",
  },
  SyBase: {
    id: "SyBase",
    image: "/images/sybase.png",
    keywords: ["SQL"],
    name: "SyBase",
    languages: [languages.sql],
    types: [TechnologyType.Database],
  },
  SyncFusion: {
    id: "SyncFusion",
    image: "/images/syncfusion.png",
    name: "SyncFusion",
    languages: [languages.csharp, languages.xaml],
    types: [TechnologyType.Frontend],
  },
  Swarmpit: {
    id: "Swarmpit",
    image: "/images/swarmpit.png",
    name: "Swarmpit",
    types: [TechnologyType.DevOps],
    url: "https://swarmpit.io/",
  },
  Teams: {
    id: "Teams",
    image: "/images/teams.png",
    name: "Teams",
    types: [TechnologyType.KnowledgeSharing],
  },
  Tomcat: {
    id: "Tomcat",
    image: "/images/tomcat.png",
    name: "Tomcat",
    types: [TechnologyType.Backend],
  },
  Xamarin: {
    id: "Xamarin",
    image: "/images/xamarin.png",
    keywords: ["C#", "Xamarin", "Microsoft", "Mobile", ".NET Core"],
    languages: [languages.csharp, languages.xaml],
    name: "Xamarin",
    types: [TechnologyType.Frontend],
  },
  VanillaExtract: {
    id: "VanillaExtract",
    image: "/images/vanilla-extract.png",
    languages: [languages.javascript, languages.typescript],
    name: "Vanilla Extract",
    types: [TechnologyType.Frontend],
    url: "https://vanilla-extract.style/",
  },
  Vue: {
    id: "Vue",
    image: "/images/vue.png",
    languages: [languages.javascript, languages.typescript],
    name: "Vue",
    types: [TechnologyType.Frontend],
    url: "https://vuejs.org/",
  },
  WildFly: {
    id: "WildFly",
    image: "/images/wildfly.png",
    name: "WildFly",
    types: [TechnologyType.Backend],
  },
  Wcf: {
    id: "Wcf",
    image: "/images/dotnet.png",
    keywords: ["SOAP"],
    languages: [languages.csharp, languages.xml],
    name: "WCF",
    types: [TechnologyType.Backend],
    url: "https://docs.microsoft.com/en-us/dotnet/framework/wcf/",
  },
};

export const clients: Client[] = [
  {
    id: "Tom",
    description:
      "I create projects to improve my understanding and challenge my abilities.  This often helps with prototyping new ideas, experimenting with new technologies or just having a laugh at making silly projects.",
    image: "/images/tsco.png",
    industry: Industries.None,
    name: "Tom's projects",
    startDate: new Date(2022, 0),
    url: "https://tsco.dev",
    projects: [
      {
        id: "TomTscoDev",
        image: "/images/tsco.png",
        name: "Tsco.dev website",
        technologies: [
          tech.ESLint,
          tech.FirebaseHosting,
          tech.GitHubActions,
          tech.GitHub,
          tech.Mantine,
          tech.Jest,
          tech.React,
          tech.ReactTestingLibrary,
          tech.Storybook,
        ],
        url: "https://tsco.dev",
      },
    ],
  },
  {
    id: "Carsales",
    description:
      "carsales.com is an online marketplace, which specialises in automotive, motorcycle and marine classifieds business in Australia. Within Australia, carsales.com manages a network of sites for online classifieds as well as other sites related to the automotive industry.",
    image: "/images/carsales.png",
    industry: Industries.OnlineMarketplace,
    name: "carsales",
    personalDescription:
      "As a software engineer in the Research squad, I am responsible for maintaining and enhancing content related to research and liasoning with stakeholders.  This requires working across multiple teams, technologies and stakeholders as part of a microfront end design to support research content across different tenants and applications.  Additionally as an engineer on the new carsales design library project (Torq), I was responsible for implementing the core components for future use in carsales as well as assisting designers with technical challenges such as design token transformation and implementation considerations.",
    url: "https://carsales.com.au/",
    startDate: new Date(2022, 4),
    projects: [
      {
        id: "CarsalesResearch",
        description:
          "Research is a broad range of content across all carsales verticals including editorial reviews, owner reviews, car research and valuations pages.  This required working across multiple teams as part of a microfront end design to support research content across different tenants and applications.",
        image: "/images/carsales.png",
        name: "carsales Research",
        personalDescription:
          "As part of the Research squad, I was responsible for maintaining and enhancing content related to research and liasoning with stakeholders",
        technologies: [
          tech.Angular,
          tech.AtlassianJira,
          tech.AwsCloudFront,
          tech.AwsCloudWatch,
          tech.AwsDynamoDB,
          tech.AwsEc2,
          tech.AwsEcs,
          tech.AwsLambda,
          tech.AwsS3,
          tech.Git,
          tech.GitHub,
          tech.GitHubActions,
          tech.Mediatr,
          tech.NewRelic,
          tech.NetCoreCSharp,
          tech.NLog,
          tech.NuGet,
          tech.OpenAPI,
          tech.PagerDuty,
          tech.PostgreSQL,
          tech.React,
          tech.SumoLogic,
        ],
      },
      {
        id: "CarsalesTorq",
        description:
          "As part of redeveloping the public carsales website, a new custom design library project (Torq) was implemented with the key goal of providing a new face to the carsales website as part of the wider project (Merlin) to improve performance and SEO optimizations.",
        image: "/images/carsales.png",
        name: "Torq",
        personalDescription:
          "As part of the implementing the new designs from the design library, I was responsive for creating one for one implementations of components in React and sourcing styling values from design tokens.  This required numerous discussions, overcoming challenges such as token structure and transformations.",
        technologies: [
          tech.AtlassianJira,
          tech.AwsEcs,
          tech.AwsS3,
          tech.Figma,
          tech.Git,
          tech.GitHub,
          tech.GitHubActions,
          tech.Mantine,
          tech.React,
          tech.Storybook,
          tech.VanillaExtract,
        ],
      },
      {
        id: "CarsalesMerlin",
        description:
          "Project Merlin was initiated as part of a facelift of the public carsales site and applications, this included implementing new back end services to support all the platform clients (Android, iOS and web) with a new API that provided a combination of the screen data as well as the screen layout/content.",
        image: "/images/carsales.png",
        name: "Merlin",
        technologies: [
          tech.AtlassianJira,
          tech.AwsCloudFront,
          tech.AwsCloudWatch,
          tech.AwsEcs,
          tech.AwsS3,
          tech.Git,
          tech.GitHub,
          tech.GitHubActions,
          tech.NewRelic,
          tech.NextJs,
          tech.NetCoreCSharp,
          tech.NLog,
          tech.NuGet,
          tech.OpenAPI,
          tech.PagerDuty,
          tech.React,
          tech.Storybook,
          tech.SumoLogic,
          tech.VanillaExtract,
        ],
      },
    ],
  },
  {
    id: "Crank",
    description:
      "CRANK is Australia’s only Registered NDIS provider dedicated to delivering inclusive and interest focused career programs and employment services.",
    endDate: new Date(2022, 1),
    image: "/images/crank.png",
    industry: Industries.SupportService,
    name: "Crank",
    personalDescription:
      "As a Platform Engineer, I worked in a small team at a start-up and was primarily responsible for the development of a digital platform for the Crank business, which provides career programs and coaching services to NDIS participants.  The Crank platform provides real-time communication and social network features for Crank clients and coaches.",
    url: "https://www.crank.com.au/",
    startDate: new Date(2021, 9),
    projects: [
      {
        id: "CrankAPI",
        description:
          "The Crank platform includes an API layer that allows the mobile client to communicate securely with the Crank platform.",
        image: "/images/crank.png",
        keywords: ["REST", "swagger"],
        name: "Crank API",
        personalDescription:
          "The Crank API involved identifying data requirements and developing secured API endpoints to support business operations and client interactions with the platform.",
        technologies: [
          tech.AtlassianJira,
          tech.AzureAD,
          tech.AzureAppConfiguration,
          tech.AzureArtifacts,
          tech.AzureB2C,
          tech.AzureBlobStorage,
          tech.AzureBoards,
          tech.AzureCommsServices,
          tech.AzureCosmosDB,
          tech.AzureDevOps,
          tech.AzureEventGrid,
          tech.AzureFunctions,
          tech.AzureLogicApps,
          tech.AzureNotificationHubs,
          tech.AzurePipelines,
          tech.AzureRepos,
          tech.EntityFramework,
          tech.Git,
          tech.NetCoreCSharp,
          tech.NuGet,
          tech.OpenAPI,
        ],
      },
      {
        id: "CrankMobile",
        description:
          "The Crank mobile client provides the primary digital interaction point between clients and coaches in the form of social features, chat and video calling.",
        image: "/images/crank.png",
        keywords: ["REST", "swagger"],
        name: "Crank Mobile",
        personalDescription:
          "The Crank mobile client was developed in Microsoft Xamarin with support for Android and iOS devices.  The mobile client primarily interacted with lambda functions hosted as Azure Functions with data stored in a document database in Azure Cosmos DB.",
        technologies: [
          tech.AppCenter,
          tech.AtlassianJira,
          tech.AzureAppConfiguration,
          tech.AzureArtifacts,
          tech.AzureB2C,
          tech.AzureBoards,
          tech.AzureCommsServices,
          tech.AzureDevOps,
          tech.AzureEventGrid,
          tech.AzureNotificationHubs,
          tech.AzurePipelines,
          tech.AzureRepos,
          tech.FirebaseDynamicLinks,
          tech.Git,
          tech.NetCoreCSharp,
          tech.NuGet,
          tech.SyncFusion,
          tech.Xamarin,
        ],
      },
    ],
  },
  {
    id: "Edalex",
    description:
      "Edalex is an edtech company on a mission to surface learning outcomes, digital assets and the power of individual achievement. Founded in 2016, Edalex develops technology solutions that extract hidden value from educational data to make it accessible and more meaningful.",
    endDate: new Date(2021, 10),
    image: "/images/edalex.png",
    industry: Industries.Education,
    keywords: ["Education", "Edtech", "Edalex"],
    name: "Edalex",
    url: "https://edalex.com",
    startDate: new Date(2019, 9),
    projects: [
      {
        id: "Edalex-Credentialate",
        image: "/images/credentialate.png",
        keywords: [
          "Badges",
          "Education",
          "Edtech",
          "Edalex",
          "Microcredentials",
          "OAuth",
          "REST",
          "Swagger",
        ],
        name: "Credentialate",
        technologies: [
          tech.AtlassianConfluence,
          tech.AtlassianJira,
          tech.AwsApiGateway,
          tech.AwsCloudWatch,
          tech.AwsCognito,
          tech.AwsEcr,
          tech.AwsEcs,
          tech.AwsLambda,
          tech.AwsRds,
          tech.AwsSns,
          tech.AwsSqs,
          tech.AwsS3,
          tech.Blackboard,
          tech.Brightspace,
          tech.Canvas,
          tech.CodeNarc,
          tech.Docker,
          tech.Geb,
          tech.Git,
          tech.Gradle,
          tech.Jest,
          tech.Kibana,
          tech.Liquibase,
          tech.MaterialUi,
          tech.Micronaut,
          tech.Moodle,
          tech.Nexus,
          tech.OpenAPI,
          tech.PostgreSQL,
          tech.React,
          tech.ReactTestingLibrary,
          tech.Spock,
          tech.Storybook,
        ],
      },
      {
        id: "Edalex-ECS",
        image: "/images/oeq.png",
        keywords: [
          "Badges",
          "Education",
          "Edtech",
          "Edalex",
          "Microcredentials",
          "OAuth",
          "REST",
        ],
        name: "Edalex Content Services",
        shortName: "ECS",
        technologies: [
          tech.AtlassianConfluence,
          tech.AtlassianJira,
          tech.AwsCloudFront,
          tech.AwsCloudWatch,
          tech.AwsMediaConvert,
          tech.AwsLambda,
          tech.AwsSns,
          tech.AwsS3,
          tech.CodeNarc,
          tech.Docker,
          tech.Git,
          tech.Gradle,
          tech.Jest,
          tech.Micronaut,
          tech.Nexus,
          tech.OpenAPI,
          tech.PostgreSQL,
          tech.React,
          tech.Spock,
          tech.Storybook,
          tech.Swarmpit,
        ],
      },
    ],
  },
  {
    id: "RACTI",
    description:
      "The Royal Automobile Club of Tasmania normally abbreviated to RACT is a motoring club in Tasmania, Australia. The organisation was established in 1923 and now has over 200,000 members.[1] It is a member of the Australian Automobile Association. It provides services such as roadside assistance, vehicle, home and contents insurance, personal and car loans, driver's education and tourism.",
    endDate: new Date(2019, 9),
    image: "/images/ract.png",
    industry: Industries.Insurance,
    keywords: ["Insurance", "RACT"],
    name: "RACT Insurance",
    personalDescription:
      "As a Senior Developer, I was a member within the RACT Insurance (RACTI) business development team to deliver new online functionality to customers. This has required the ability to rapidly gain an understanding of rigid and complex insurance business/system rules, meeting tight deadlines and providing a streamlined website user experience for customers while maintaining a high degree of quality",
    shortName: "RACT",
    startDate: new Date(2018, 8),
    projects: [
      {
        id: "RACT-B2C",
        image: "/images/ract.png",
        keywords: ["REST", "swagger"],
        name: "Online Quoting",
        technologies: [
          tech.AspNetCore,
          tech.AtlassianConfluence,
          tech.AtlassianJira,
          tech.AzureB2C,
          tech.AzureFunctions,
          tech.EntityFramework,
          tech.JetBrainsTeamCity,
          tech.Git,
          tech.IIS,
          tech.Moq,
          tech.NetCoreCSharp,
          tech.NuGet,
          tech.NUnit,
          tech.OpenAPI,
          tech.React,
          tech.Redux,
          tech.Powershell,
          tech.ReSharper,
          tech.ShuttleEsb,
          tech.SQLServer,
          tech.Storybook,
        ],
      },
      {
        id: "RACT-Claims",
        image: "/images/ract.png",
        keywords: ["REST", "swagger"],
        name: "Online Claims",
        technologies: [
          tech.AspNetCore,
          tech.AtlassianConfluence,
          tech.AtlassianJira,
          tech.AzureB2C,
          tech.AzureCosmosDB,
          tech.AzureBlobStorage,
          tech.AzureFunctions,
          tech.EntityFramework,
          tech.Figma,
          tech.Git,
          tech.IIS,
          tech.Jest,
          tech.Moq,
          tech.MaterialUi,
          tech.NetCoreCSharp,
          tech.NuGet,
          tech.NUnit,
          tech.OpenAPI,
          tech.JetBrainsTeamCity,
          tech.Powershell,
          tech.React,
          tech.Redux,
          tech.ReSharper,
          tech.ShuttleEsb,
          tech.SQLServer,
          tech.Storybook,
        ],
      },
      {
        id: "RACT-PolicyManagement",
        image: "/images/ract.png",
        keywords: ["REST", "swagger"],
        name: "Policy Management",
        technologies: [
          tech.AspNetCore,
          tech.AtlassianConfluence,
          tech.AtlassianJira,
          tech.AzureB2C,
          tech.EntityFramework,
          tech.Figma,
          tech.Git,
          tech.IIS,
          tech.Jest,
          tech.JetBrainsTeamCity,
          tech.Moq,
          tech.NetCoreCSharp,
          tech.NuGet,
          tech.NUnit,
          tech.Powershell,
          tech.OpenAPI,
          tech.React,
          tech.Redux,
          tech.ReSharper,
          tech.ShuttleEsb,
          tech.SQLServer,
          tech.Storybook,
        ],
      },
    ],
  },
  {
    id: "DPFEM",
    description:
      "The Department of Police, Fire and Emergency Management (DPFEM) provides policing and emergency management services to the Tasmanian community.",
    endDate: new Date(2018, 0),
    image: "/images/tasgov.png",
    industry: Industries.Government,
    keywords: ["DPFEM", "firefighters", "police", "state government"],
    name: "Department of Police, Fire and Emergency Management",
    shortName: "DPFEM",
    startDate: new Date(2016, 0),
    personalDescription:
      "As a Senior Developer, I was responsible for delivering new and supporting existing Police and Fire applications. I assumed responsibility for several applications, providing face to face     and remote support for reported issues, assisting during service outages and delivering new functionality. Thomas assisted with the migration of applications to a new database server which included documenting the steps involved and confirming interoperability between applications.",
    projects: [
      {
        id: "DPFEM-Police-Java",
        image: "/images/taspol.png",
        keywords: ["kerberos", "REST", "swagger"],
        name: "Police Java Projects",
        technologies: [
          tech.ActiveDirectory,
          tech.Artifactory,
          tech.AtlassianJira,
          tech.Bootstrap,
          tech.CodeNarc,
          tech.Geb,
          tech.GlassFish,
          tech.Gradle,
          tech.Grails,
          tech.JQuery,
          tech.JQueryUI,
          tech.Solr,
          tech.Spock,
          tech.SQLServer,
          tech.Subversion,
          tech.WildFly,
        ],
      },
      {
        id: "DPFEM-Police-Dotnet",
        image: "/images/taspol.png",
        keywords: ["REST", "swagger"],
        name: "Police .NET Projects",
        technologies: [
          tech.ActiveDirectory,
          tech.Angular,
          tech.Artifactory,
          tech.AspNetMvc,
          tech.AspNetCore,
          tech.AtlassianJira,
          tech.Bootstrap,
          tech.EntityFramework,
          tech.IIS,
          tech.NuGet,
          tech.NUnit,
          tech.OpenAPI,
          tech.Solr,
          tech.SQLServer,
          tech.Subversion,
          tech.Vue,
          tech.Wcf,
        ],
      },
      {
        id: "DPFEM-Police-ANPRS",
        image: "/images/taspol.png",
        keywords: ["REST", "swagger"],
        name: "Automatic Numberplate Recognition System",
        shortName: "ANPRS",
        technologies: [
          tech.ActiveDirectory,
          tech.Artifactory,
          tech.AspNetCore,
          tech.AtlassianJira,
          tech.Bootstrap,
          tech.EntityFramework,
          tech.IIS,
          tech.NUnit,
          tech.NetCoreCSharp,
          tech.NuGet,
          tech.OpenAPI,
          tech.SQLServer,
          tech.Subversion,
          tech.Vue,
        ],
      },
      {
        id: "DPFEM-Police-ESCAD",
        image: "/images/escad.png",
        keywords: ["REST", "swagger"],
        name: "Electronic Computer Aided Dispatch",
        shortName: "ESCAD",
        technologies: [
          tech.Artifactory,
          tech.AspNetCore,
          tech.AtlassianJira,
          tech.NuGet,
          tech.SSIS,
          tech.SSRS,
          tech.SQLServer,
          tech.Subversion,
        ],
      },
      {
        id: "DPFEM-Police-TasPolSearch",
        image: "/images/taspol.png",
        keywords: ["REST", "swagger"],
        name: "TasPol Search",
        technologies: [
          tech.ActiveDirectory,
          tech.Artifactory,
          tech.AtlassianJira,
          tech.Bootstrap,
          tech.CodeNarc,
          tech.Gradle,
          tech.Grails,
          tech.Nagios,
          tech.NuGet,
          tech.Solr,
          tech.Spock,
          tech.SQLServer,
          tech.Subversion,
        ],
      },
      {
        id: "DPFEM-RCR",
        image: "/images/tasfire.png",
        name: "Road Crash Rescue",
        shortName: "RCR",
        technologies: [
          tech.ActiveDirectory,
          tech.AspNetCore,
          tech.AtlassianJira,
          tech.EntityFramework,
          tech.NuGet,
          tech.IIS,
          tech.NetCoreCSharp,
          tech.NUnit,
          tech.OpenAPI,
          tech.SQLServer,
          tech.Subversion,
          tech.SyBase,
          tech.Vue,
        ],
      },
      {
        id: "DPFEM-TFS-Website",
        image: "/images/tasfire.png",
        keywords: ["oauth"],
        name: "Tasmania Fire Service Website",
        shortName: "TFS Website",
        technologies: [
          tech.Ant,
          tech.AtlassianJira,
          tech.AzureAD,
          tech.AzureB2C,
          tech.GlassFish,
          tech.JavaEE,
          tech.JQuery,
          tech.JQueryUI,
          tech.JUnit,
          tech.Nagios,
          tech.NewRelic,
          tech.Subversion,
          tech.SyBase,
        ],
      },
    ],
  },
  {
    id: "Centrelink",
    description:
      "The Centrelink Master Program, or more commonly known as Centrelink, is a Services Australia master program of the Australian Government. The majority of Centrelink's services are the disbursement of social security payments.",
    endDate: new Date(2012, 0),
    image: "/images/centrelink.png",
    industry: Industries.Government,
    keywords: ["consulting", "dialog"],
    name: "Centrelink",
    personalDescription:
      "As a consultant through Dialog, I joined a handful of Centrelink teams as a mainframe developer across child care related benefits including Paid Parental Leave (PPL), Dad and Partner Pay and Jobs (DAPP), Education and Training Child Care Fee Assistance (JETCCFA).  This position required rapidly gaining an understanding of the benefits to implement the business rules, terminal (green) screens and web services to be used by the Java team.",
    startDate: new Date(2010, 0),
    projects: [
      {
        id: "Centrelink-DAPP",
        image: "/images/centrelink.png",
        name: "Dad and Partner Pay",
        shortName: "DAPP",
        technologies: [tech.m204],
      },
      {
        id: "Centrelink-JETCCFA",
        image: "/images/centrelink.png",
        name: "Job Education Training Childcard Fund Assistance",
        shortName: "JETCCFA",
        technologies: [tech.m204],
      },
      {
        id: "Centrelink-PPL",
        image: "/images/centrelink.png",
        name: "Paid Parental Leave",
        shortName: "PPL",
        technologies: [tech.m204],
      },
    ],
  },
  {
    id: "DPIPWE",
    description:
      "The Tasmanian Department of Primary Industries, Parks, Water and Environment (DPIPWE) is the government department of the Tasmanian Government responsible for supporting primary industry development, the protection of Tasmania's natural environment, effective land and water management and the protection of Tasmania's relative disease and pest free status.",
    endDate: new Date(2016, 0),
    image: "/images/tasgov.png",
    industry: Industries.Government,
    keywords: ["consulting", "dialog"],
    name: "Department of Primary Industry, Parks, Wildlife and the Environment",
    personalDescription:
      "As a consultant through Dialog, I was responsible for developing and enhancing several systems including Parks Passes, Surveyor Register, Crown Real-Estate System of Tasmania and Valuation Information System for Tasmania.",
    shortName: "DPIPWE",
    startDate: new Date(2013, 0),
    projects: [
      {
        id: "DPIPWE-Crest",
        image: "/images/tasgov.png",
        name: "Crown Real-Estate System of Tasmania",
        shortName: "CREST",
        technologies: [
          tech.AtlassianBamboo,
          tech.AtlassianJira,
          tech.MrArchitect,
          tech.MsArchitect,
          tech.Subversion,
          tech.Tomcat,
        ],
      },
      {
        id: "DPIPWE-GameLicensing",
        image: "/images/tasgov.png",
        name: "Recreational Game Licensing",
        technologies: [
          tech.AtlassianBamboo,
          tech.AtlassianJira,
          tech.Bootstrap,
          tech.CodeNarc,
          tech.Geb,
          tech.Git,
          tech.Gradle,
          tech.Grails,
          tech.JQuery,
          tech.JQueryUI,
          tech.Tomcat,
        ],
      },
      {
        id: "DPIPWE-ParksPasses",
        image: "/images/tas_parks.png",
        name: "Parks Passes",
        technologies: [
          tech.AtlassianBamboo,
          tech.AtlassianConfluence,
          tech.AtlassianCrucible,
          tech.AtlassianJira,
          tech.Bootstrap,
          tech.CodeNarc,
          tech.Geb,
          tech.Git,
          tech.Gradle,
          tech.Grails,
          tech.JQuery,
          tech.JQueryUI,
          tech.PostgreSQL,
          tech.Spock,
          tech.Tomcat,
        ],
      },
      {
        id: "DPIPWE-Surveyors",
        image: "/images/tasgov.png",
        name: "Surveyors Register",
        technologies: [
          tech.AtlassianBamboo,
          tech.AtlassianConfluence,
          tech.AtlassianCrucible,
          tech.AtlassianJira,
          tech.Bootstrap,
          tech.CodeNarc,
          tech.Geb,
          tech.Git,
          tech.Gradle,
          tech.Grails,
          tech.JQuery,
          tech.JQueryUI,
          tech.PostgreSQL,
          tech.Spock,
          tech.Tomcat,
        ],
      },
      {
        id: "DPIPWE-Vistas",
        image: "/images/tasgov.png",
        name: "Valuation Information System for Tasmania",
        shortName: "VISTAS",
        technologies: [
          tech.AtlassianJira,
          tech.MrArchitect,
          tech.MsArchitect,
          tech.Subversion,
          tech.Tomcat,
        ],
      },
      {
        id: "DPIPWE-Wod",
        image: "/images/tasgov.png",
        name: "Water Operations Database",
        shortName: "WOD",
        technologies: [
          tech.Angular,
          tech.AtlassianBamboo,
          tech.AtlassianJira,
          tech.IndexedDb,
          tech.PostgreSQL,
          tech.Git,
          tech.Tomcat,
        ],
      },
    ],
  },
  {
    id: "DEDTA",
    description:
      "The Department of Economic Development, Tourism and the Arts (DEDTA) was the Tasmanian Government Department responsible for leading economic and industry development in Tasmania.",
    endDate: new Date(2012, 0),
    image: "/images/tasgov.png",
    industry: Industries.Government,
    keywords: ["consulting", "dialog"],
    name: "Department of Economic Development, Tourism and the Arts",
    shortName: "DEDTA",
    startDate: new Date(2012, 0),
    personalDescription:
      "As part of a small team, I helped develop the InvestMap website which provided a geospatial solution for managing and viewing investment projects by the Tasmanian state government",
    projects: [
      {
        id: "Dialog-InvestMap",
        image: "/images/tasgov.png",
        keywords: ["geographical"],
        name: "InvestMap",
        shortName: "InvestMap",
        technologies: [
          tech.AtlassianJira,
          tech.Bootstrap,
          tech.GWT,
          tech.PostgreSQL,
          tech.Subversion,
          tech.Tomcat,
        ],
      },
    ],
  },
  {
    id: "UTAS",
    description:
      "The University of Tasmania (UTAS) is a public research university, primarily located in Tasmania, Australia. Founded in 1890",
    endDate: new Date(2013, 0),
    image: "/images/utas.png",
    industry: Industries.Education,
    keywords: ["consulting", "dialog"],
    name: "UTAS",
    startDate: new Date(2012, 0),
    personalDescription:
      "As a consultant through Dialog, I developed a web application to manage student articulation which allowed administrators the ability to map past accreditation from other institutions to UTAS courses and allow public users the ability to lookup articulation options.  I managed the testing and QA of the Online Admissions project, providing testing of code development by a Dialog team and reports to the UTAS client.",
    projects: [
      {
        id: "UTAS-ART",
        image: "/images/utas.png",
        keywords: ["REST"],
        name: "Articulation",
        technologies: [
          tech.AspNetMvc,
          tech.AtlassianJira,
          tech.Bootstrap,
          tech.EntityFramework,
          tech.JQuery,
          tech.IIS,
          tech.SQLServer,
          tech.Subversion,
        ],
      },
      {
        id: "UTAS-OLA",
        image: "/images/utas.png",
        keywords: ["REST"],
        name: "Online Admissions",
        shortName: "OLA",
        technologies: [
          tech.AspNetMvc,
          tech.AtlassianJira,
          tech.Bootstrap,
          tech.JQuery,
          tech.IIS,
          tech.SQLServer,
          tech.Subversion,
        ],
      },
    ],
  },
  {
    id: "Prologic",
    description:
      "Prologic was a software development and consultancy company with offices in Hobart, Launceston and Melbourne.",
    endDate: new Date(2010, 0),
    image: "/images/prologic.jpg",
    industry: Industries.Consulting,
    keywords: ["consulting"],
    name: "Prologic",
    startDate: new Date(2008, 0),
    personalDescription:
      "As the System Administrator, I was responsible for the management and support of all technical systems within Prologic, including websites, PCs, servers, printers, telecommunication and network devices. I provided technical support for staff and on a range of internal and client-based projects, including acting as second level support for client infrastructure.",
    projects: [
      {
        id: "AutoRent",
        image: "/images/autorent.png",
        name: "AutoRent Hertz",
        shortName: "AutoRent",
        technologies: [tech.JQuery, tech.Subversion],
      },
    ],
  },
];
