import { useState } from "react";
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import AppHeader from "./components/Layout/AppHeader";
import appTheme from "./theme/AppTheme";
import AboutPage from "./pages/AboutPage";
import PortfolioPage from "./pages/PortfolioPage";
import CustomFonts from "./theme/CustomFonts";

const links = [
  {
    link: "/portfolio",
    label: "Portfolio",
  },
  {
    link: "/about",
    label: "About",
  },
];

const App = () => {
  const [active, setActive] = useState(links[0].link);
  const [colorScheme, setColorScheme] = useState<ColorScheme>("dark");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ ...appTheme, colorScheme }}
        withGlobalStyles
        withNormalizeCSS
      >
        <CustomFonts />
        <AppHeader active={active} links={links} setActive={setActive} />
        <Content active={active} />
      </MantineProvider>
    </ColorSchemeProvider>
  );
};

const Content = ({ active }: { active: string }) => {
  switch (active) {
    case "/portfolio":
      return <PortfolioPage />;
    default:
      return <AboutPage />;
  }
};

export default App;
