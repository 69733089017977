import { cloneDeep } from "lodash";
import { GraphData, LinkObject, NodeObject } from "react-force-graph-2d";
import {
  Client,
  PortfolioNode,
  Project,
  Technology,
} from "../data/StaticPortfolioTypes";

/**
 * Types of nodes in portfolio layout
 */
export enum PortfolioNodeType {
  clients = "Organisations",
  languages = "Languages",
  platforms = "Cloud Platforms",
  projects = "Projects",
  technologies = "Technology / Services",
}

export interface PortfolioFilter {
  nodeTypes: string[];
  stripDescendants: boolean;
  languages: PortfolioNode[];
  technologyTypes: string[];
  text: string;
}

export type PortfolioGraphViewType = "2D" | "3D" | "Timeline";

/**
 * Node object in portfolio layout
 */
export type PortfolioNodeObject = NodeObject &
  PortfolioNode & { opacity?: number; nodeType: PortfolioNodeType };

export const applyClientFilter = (
  clients: Client[],
  filter: PortfolioFilter,
  selectedNode: PortfolioNode | undefined
): Client[] => {
  // Filter to selected clients/projects
  let filteredClients = cloneDeep(clients);

  if (selectedNode) {
    const showNode = (node: PortfolioNode | undefined): boolean =>
      !!node && node.id === selectedNode.id;
    filteredClients = filteredClients
      .filter((c) => {
        if (showNode(c)) {
          return true; // Include all descendants
        }
        c.projects = c.projects.filter((p) => {
          if (showNode(p)) {
            return p;
          }
          p.technologies = p.technologies.filter(
            (t) =>
              showNode(t) || showNode(t.platform) || t.languages?.some(showNode)
          );
          return !!p.technologies.length;
        });

        return !!c.projects.length; // Only include clients that have any matching projects
      })
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  }

  const text = filter.text.toLowerCase().trim();
  if (text) {
    filteredClients = filter.stripDescendants
      ? filteredClients
          .map((c) => {
            stripClientDescendantsWithoutText(c, text);
            return c;
          })
          .filter((c) => !!c.projects.length || nodeHasText(c, text))
      : filteredClients.filter((c) => clientHasText(c, text));
  }

  return filteredClients;
};

export const isClientNode = (node: Client | PortfolioNode): node is Client => {
  return (node as Client).projects !== undefined;
};

export const techHasLanguageAndType = (
  tech: Technology,
  filter: PortfolioFilter
): boolean => {
  const hasLanguage =
    !filter.languages.length ||
    !!tech.languages?.some((l) =>
      filter.languages.some((fl) => fl.id === l.id)
    );
  const hasType =
    !filter.technologyTypes.length ||
    filter.technologyTypes.includes(tech.types.toString());
  return hasLanguage && hasType;
};

/**
 * Construct graph data for collection of clients, extracting nodes and links
 * @param clients Details for clients including projects
 * @param filter Details to filter nodes by
 * @returns Graph data for use in portfolio layout
 */
export const clientsToGraphData = (
  clients: Client[],
  filter: PortfolioFilter
): GraphData => {
  const filtered = cloneDeep(clients).filter((c) => {
    c.projects.forEach((p) => {
      p.technologies = p.technologies.filter((t) => {
        t.languages = t.languages?.filter(
          (l) =>
            !filter.languages.length ||
            filter.languages.some((fl) => fl.id === l.id)
        );
        return techHasLanguageAndType(t, filter);
      });
    });
    c.projects = c.projects.filter((p) => !!p.technologies.length);
    return !!c.projects.length;
  });
  return {
    links: filtered.flatMap((c) => clientToLinks(c, filter)),
    // links: [],
    nodes: filtered
      .flatMap((c) => clientToNodes(c, filter))
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      ),
  };
};

/**
 * Construct collection of links between client nodes
 * @param client Details for clients including projects
 * @param filter Details to filter nodes by
 * @returns Links between nodes
 */
const clientToLinks = (client: Client, filter: PortfolioFilter): LinkObject[] =>
  client.projects.reduce<LinkObject[]>((links, project) => {
    // Client to project link
    if (
      includeNodeTypes(
        filter,
        PortfolioNodeType.clients,
        PortfolioNodeType.projects
      )
    ) {
      links.push({
        source: client.id,
        target: project.id,
      });
    }

    project.technologies.forEach((tech) => {
      try {
        const techSourceId = includeNodeTypes(
          filter,
          PortfolioNodeType.projects
        )
          ? project.id
          : includeNodeTypes(filter, PortfolioNodeType.clients)
          ? client.id
          : undefined;

        // Technology to client or project link
        const includeTechNode = includeNodeTypes(
          filter,
          PortfolioNodeType.technologies
        );
        if (includeTechNode) {
          if (techSourceId) {
            links.push({
              source: techSourceId,
              target: tech.id,
            });
          }
        }

        const techChildSourceId = includeTechNode ? tech.id : techSourceId;

        if (techChildSourceId) {
          // Language to technology link
          if (
            tech.languages &&
            includeNodeTypes(filter, PortfolioNodeType.languages)
          ) {
            tech.languages.forEach((l) => {
              links.push({
                source: techChildSourceId,
                target: l.id,
              });
            });
          }
          // Platform to technology link
          if (
            tech.platform &&
            includeNodeTypes(filter, PortfolioNodeType.platforms)
          ) {
            links.push({
              source: techChildSourceId,
              target: tech.platform.id,
            });
          }
        }
      } catch (error) {
        console.error("Failed to process techology", project, tech, error);
      }
    });

    return links;
  }, []);

/**
 * Construct nodes from details for a single client
 * @param client Client details
 * @param filter Details to filter nodes by
 * @returns Graph nodes
 */
const clientToNodes = (
  client: Client,
  filter: PortfolioFilter
): PortfolioNodeObject[] => {
  const nodes: PortfolioNodeObject[] = [];

  if (includeNodeTypes(filter, PortfolioNodeType.clients)) {
    nodes.push({
      ...client,
      nodeType: PortfolioNodeType.clients,
    });
  }

  return [
    ...nodes,
    ...client.projects.flatMap((p) => projectToNodes(p, filter)),
  ];
};

/**
 * Get empty filter for portfolio
 * @returns Empty filter
 */
export const emptyPortfolioFilter = (): PortfolioFilter => ({
  languages: [],
  nodeTypes: [],
  stripDescendants: true,
  technologyTypes: [],
  text: "",
});

/**
 * Flatten clients to list of nodes consisting of all nodes of every client including the client
 * @param clients Clients to flatten
 * @returns Flatten list of nodes
 */
export const flattenClientsToAllNodes = (clients: Client[]): PortfolioNode[] =>
  clients.reduce<PortfolioNode[]>((results, client) => {
    const projectNodes = client.projects.reduce<PortfolioNode[]>(
      (nodes, project) => {
        try {
          const languages = project.technologies.flatMap(
            (t) => t.languages ?? []
          );
          const platforms = project.technologies.flatMap(
            (t) => t.platform ?? []
          );
          return [
            ...nodes,
            project,
            ...project.technologies,
            ...(languages ?? []),
            ...(platforms ?? []),
          ];
        } catch (error) {
          console.error("Failed to flatten project", project, error);
        }
        return nodes;
      },
      []
    );

    return [...results, client, ...projectNodes];
  }, []);

/**
 * Flatten clients to list of nodes consisting of the client and projects
 * @param clients Clients to flatten
 * @returns Flatten list of nodes
 */
export const flattenClientsToClientAndProjectNodes = (
  clients: Client[]
): PortfolioNode[] =>
  clients.reduce<PortfolioNode[]>((results, client) => {
    return [...results, client, ...client.projects];
  }, []);

/**
 * Evaluate whether to include a given type of node based on the selected node types filter
 * @param filter Details to filter node by
 * @param types Types of node to evaluate
 * @returns Flag indicating whether to include the node
 */
const includeNodeTypes = (
  filter: PortfolioFilter,
  ...types: PortfolioNodeType[]
): boolean =>
  !filter.nodeTypes?.length ||
  types.every((t) => t && filter.nodeTypes.includes(t));

export const portfolioNodeNameComparator = (
  a: PortfolioNode,
  b: PortfolioNode
) =>
  (a.shortName ?? a.name).toLowerCase() > (b.shortName ?? b.name).toLowerCase()
    ? 1
    : -1;

/**
 * Construct nodes from details for a single project
 * @param project Project details
 * @param filter Details to filter nodes by
 * @returns Graph nodes
 */
const projectToNodes = (
  project: Project,
  filter: PortfolioFilter
): PortfolioNodeObject[] => {
  const nodes: PortfolioNodeObject[] = [];

  if (includeNodeTypes(filter, PortfolioNodeType.projects)) {
    nodes.push({
      ...project,
      nodeType: PortfolioNodeType.projects,
    });
  }

  project.technologies?.forEach((t) => {
    const hasTech =
      !filter.technologyTypes.length ||
      filter.technologyTypes.includes(t.types.toString());
    const hasLanguage =
      !filter.languages.length ||
      !!t.languages?.some((l) => filter.languages.some((fl) => fl.id === l.id));

    if (!hasTech || !hasLanguage) {
      return;
    }
    if (includeNodeTypes(filter, PortfolioNodeType.technologies)) {
      nodes.push({ ...t, nodeType: PortfolioNodeType.technologies });
    }
    if (t.platform && includeNodeTypes(filter, PortfolioNodeType.platforms)) {
      nodes.push({ ...t.platform, nodeType: PortfolioNodeType.platforms });
    }
    if (t.languages && includeNodeTypes(filter, PortfolioNodeType.languages)) {
      t.languages
        .filter(
          (l) =>
            !filter.languages.length ||
            filter.languages.some((fl) => fl.id === l.id)
        )
        .forEach((l) => {
          nodes.push({ ...l, nodeType: PortfolioNodeType.languages });
        });
    }
  });

  return nodes;
};

export const nodeHasText = (
  node: PortfolioNode | undefined,
  text: string
): boolean => {
  if (!text) {
    return true;
  }
  if (!node) {
    return false;
  }
  return (
    node.name.toLowerCase().includes(text.toLowerCase()) ||
    !!node.shortName?.toLowerCase().includes(text.toLowerCase()) ||
    !!node.keywords?.some((k) => k.toLowerCase().includes(text.toLowerCase()))
  );
};

export const clientHasText = (client: Client, text: string): boolean => {
  const hasText = (n: PortfolioNode | undefined) => nodeHasText(n, text);

  if (hasText(client)) {
    return true;
  }

  return client.projects.some(
    (p) =>
      hasText(p) ||
      p.technologies.some((t) =>
        [t, t.platform, ...(t.languages ?? [])].some(hasText)
      )
  );
};

/**
 * Filter clients and descendants to those that contain search text
 * If client has text then all nodes will be returned, if node
 * has text then all descendants of that node will be included
 * @param client Client to mutate
 * @param text Search text
 */
export const stripClientDescendantsWithoutText = (
  client: Client,
  text: string
) => {
  const hasText = (n: PortfolioNode | undefined) =>
    nodeHasText(n, text.toLowerCase());

  if (hasText(client)) {
    return; // No filter needed, include all projects
  }

  // Strip non-matching projects
  client.projects = client.projects.filter((p) => {
    if (hasText(p)) {
      return true; // Include all technologies
    }

    // Strip non-matching technologies
    p.technologies = p.technologies.filter((t) =>
      [t, t.platform, ...(t.languages ?? [])].some(hasText)
    );
    return !!p.technologies.length;
  });
};
