import { MultiSelect } from "@mantine/core";
import { Client, PortfolioNode } from "../../data/StaticPortfolioTypes";
import { useMemo, useState } from "react";
import { portfolioNodeNameComparator } from "../../utils/portfolioUtils";

interface Props {
  clients: Client[];
  selected: PortfolioNode[];
  setSelected: (p: PortfolioNode[]) => void;
  width: number;
}

const LanguageSelect = ({ clients, selected, setSelected, width }: Props) => {
  const [searchValue, onSearchChange] = useState("");

  const options = useMemo(() => {
    const languages = clients.reduce<PortfolioNode[]>((results, client) => {
      const languages = client.projects.flatMap((p) =>
        p.technologies.flatMap((t) => t.languages ?? [])
      );

      languages.forEach((l) => {
        if (!results.some((o) => o.id === l.id)) {
          results.push(l);
        }
      });
      return results;
    }, []);

    return languages.sort(portfolioNodeNameComparator);
  }, [clients]);

  const onChange = (nodes: string[]) => {
    var newSelected = options.filter((o) => nodes.includes(o.id));
    setSelected(newSelected);
  };

  return (
    <MultiSelect
      data={options.map((o) => ({ label: o.name, value: o.id }))}
      label="Languages"
      multiple
      placeholder="Select languages"
      nothingFound="No matching language found"
      onChange={onChange}
      onSearchChange={onSearchChange}
      searchable
      searchValue={searchValue}
      sx={{ width }}
      value={selected.map((o) => o.id)}
    />
  );
};

export default LanguageSelect;
