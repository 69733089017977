import { Client } from "../../data/StaticPortfolioTypes";
import { useMemo, useState } from "react";
import { PortfolioNodeType } from "../../utils/portfolioUtils";
import {
  getNodeOptionsFromClientByType,
  PortfolioNodeOption,
} from "../../utils/portfolioTreeUtils";
import PortfolioTreeItem from "./PortfolioTreeItem";
import { Select, Stack } from "@mantine/core";

interface Props {
  clients: Client[];
  searchText: string;
  selected: string | undefined;
  setSelected: (n: string | undefined) => void;
}

const PortfolioTreeView = ({
  clients,
  searchText,
  selected,
  setSelected,
}: Props) => {
  const [expanded, setExpanded] = useState<string | undefined>();
  const [treeView, setTreeView] = useState(PortfolioNodeType.clients);

  const treeNodes: PortfolioNodeOption[] = useMemo(() => {
    return getNodeOptionsFromClientByType(clients, searchText, treeView);
  }, [clients, searchText, treeView]);

  return (
    <Stack sx={{ width: 300 }}>
      <Select
        data={Object.values(PortfolioNodeType)}
        onChange={(v) => (v ? setTreeView(v as PortfolioNodeType) : undefined)}
        value={treeView}
      />
      <div>
        {treeNodes.map((node) => (
          <PortfolioTreeItem
            expanded={expanded}
            key={node.id}
            node={node}
            onSelectNode={setSelected}
            selectedNode={selected}
            setExpanded={setExpanded}
          />
        ))}
      </div>
    </Stack>
  );
};

export default PortfolioTreeView;
