import { Center, SegmentedControl, Box } from "@mantine/core";
import { Icon3dCubeSphere, IconGrain, IconReport } from "@tabler/icons";

interface Props {
  setValue: (v: string) => void;
  value: string;
}

const PortfolioGraphViewOptions = ({ setValue, value }: Props) => (
  <SegmentedControl
    data={[
      {
        value: "2D",
        label: (
          <Center>
            <IconGrain size={16} />
            <Box ml={10}>2D</Box>
          </Center>
        ),
      },
      {
        value: "3D",
        label: (
          <Center>
            <Icon3dCubeSphere size={16} />
            <Box ml={10}>3D</Box>
          </Center>
        ),
      },
      {
        value: "Timeline",
        label: (
          <Center>
            <IconReport size={16} />
            <Box ml={10}>Timeline</Box>
          </Center>
        ),
      },
    ]}
    onChange={setValue}
    radius={20}
    value={value}
  />
);

export default PortfolioGraphViewOptions;
