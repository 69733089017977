import { useMantineTheme } from "@mantine/core";

interface Props {
  className: string;
}
const AppLogo = ({ className }: Props) => {
  const theme = useMantineTheme();

  const characterColour = theme.colorScheme === "dark" ? "white" : "black";
  return (
    <svg
      className={className}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={theme.colors.brand[0]}
        d="M96.167,57.061V41.756l-10.46-3.111c-0.69-2.257-1.498-4.422-2.587-6.472l5.379-9.735L77.702,11.617L67.98,16.95    c-2.049-1.091-4.209-1.884-6.464-2.575L58.41,3.833H43.105l-3.111,10.541c-2.257,0.692-4.422,1.539-6.472,2.63l-9.735-5.36    L12.966,22.453L18.3,32.18c-1.091,2.049-2.559,4.211-3.249,6.468L3.833,41.756v15.305l11.217,3.111    c0.69,2.257,1.876,4.422,2.966,6.472l-5.192,9.735l10.893,10.821l9.77-5.333c2.049,1.089,4.231,2.476,6.489,3.165l3.129,11.136    H58.41l3.112-11.135c2.257-0.688,4.421-1.833,6.472-2.924l9.735,5.21l10.82-10.881l-5.333-9.766    c1.09-2.048,1.801-4.228,2.492-6.487L96.167,57.061z M50.758,77.858c-15.715,0-28.454-12.738-28.454-28.45    c0-15.713,12.739-28.452,28.454-28.452c15.714,0,28.451,12.739,28.451,28.452S66.469,77.858,50.758,77.858z"
      ></path>
      <path
        fill={characterColour}
        d="M52.822,38.281l-0.037-0.01c-1.002-0.272-2.034,0.319-2.306,1.321l-5.382,19.82     c-0.272,1.002,0.319,2.034,1.321,2.306l0.037,0.01c1.002,0.272,2.034-0.319,2.306-1.321l5.382-19.82     C54.415,39.586,53.824,38.553,52.822,38.281z"
      ></path>
      <path
        fill={characterColour}
        d="M40.223,41.665c0.734-0.734,0.734-1.924,0-2.658l-0.027-0.027     c-0.734-0.734-1.924-0.734-2.658,0l-8.335,8.335l-1.356,1.356c-0.734,0.734-0.734,1.924,0,2.657l1.356,1.356l8.335,8.335     c0.734,0.734,1.924,0.734,2.658,0l0.027-0.027c0.734-0.734,0.734-1.924,0-2.658l-7.006-7.006c-0.734-0.734-0.734-1.924,0-2.657     L40.223,41.665z"
      ></path>
      <path
        fill={characterColour}
        d="M72.152,48.671l-1.356-1.356l-8.335-8.335c-0.734-0.734-1.924-0.734-2.658,0     l-0.027,0.027c-0.734,0.734-0.734,1.924,0,2.658l7.006,7.006c0.734,0.734,0.734,1.924,0,2.658l-7.006,7.006     c-0.734,0.734-0.734,1.924,0,2.658l0.027,0.027c0.734,0.734,1.924,0.734,2.658,0l8.335-8.335l1.356-1.356     C72.886,50.595,72.886,49.405,72.152,48.671z"
      ></path>
    </svg>
  );
};

export default AppLogo;
