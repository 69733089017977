import { MantineThemeOverride } from "@mantine/core";

const appTheme: MantineThemeOverride = {
  colors: {
    brand: ["#5893d4"],
  },
  primaryColor: "indigo",
};

export default appTheme;
